import styled from "styled-components";

interface ItemAvatarDivProps {
  width: string;
}

export const ItemAvatarDiv = styled.div<ItemAvatarDivProps>`
  display: flex;
  width: ${(props) => props.width};
  align-self: center;
  padding-bottom: 12px;
  padding-top: 12px;
`;
