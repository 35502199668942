import { AuthenticatedTemplate } from "@azure/msal-react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Logout } from "../../app/Logout";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { useLoggedInUser } from "../../app/hooks/useLoggedInUser";
import useWindowDimensions from "../../app/hooks/useWindowDimensions";
import {
  selectIsWindowToSmall,
  setIsWindowTooSmall,
  windowMinHeight,
  windowMinWidth,
} from "../../app/slices/mainViewSlice";
import { clearSnackbar } from "../../app/slices/snackbarSlice";
import { Header } from "../../components/header/Header";
import { InformationControl } from "../../controls/InformationControl/InformationControl";
import { MainView } from "../MainView";
import { NotFoundView } from "../NotFoundView";
import { PageLayoutDiv } from "./SC/PageLayoutDiv";
import SnackbarQueue from "../../controls/Snackbar/SnackbarQueue";

export const routing = {
  details: "/document",
  newDocument: "/document/new",
  newPart: "/document/new/part",
  newLanguage: "/document/new/language",
  newTemplate: "/document/new/template",
  newRevision: "/document/new/revision",
};

export function PageLayout() {
  const dispatch = useAppDispatch();
  const { isFetchUserError } = useLoggedInUser();
  const { windowWidth, windowHeight } = useWindowDimensions();
  const isWindowTooSmall = useAppSelector(selectIsWindowToSmall);
  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (
      (windowWidth < windowMinWidth || windowHeight < windowMinHeight) &&
      !isWindowTooSmall
    ) {
      dispatch(setIsWindowTooSmall(true));
      dispatch(clearSnackbar());
    } else if (
      windowWidth >= windowMinWidth &&
      windowHeight >= windowMinHeight &&
      isWindowTooSmall
    ) {
      dispatch(setIsWindowTooSmall(false));
    }
  }, [dispatch, windowWidth, windowHeight, isWindowTooSmall]);

  if (isFetchUserError) {
    return (
      <InformationControl
        id="global-myroles"
        doCenterHeader={true}
        $showDash={false}
        showReloadButton={true}
        refreshFunction={refreshPage}
        title={"Authorization error"}
        description={`You are not authorized to access ABB
        Library Access Groups Management UI.`}
      />
    );
  }

  return (
    <PageLayoutDiv id="app-div">
      <Header />
      <Routes>
        <Route path="/" element={<MainView />} />
        <Route
          path="/logout"
          element={
            <AuthenticatedTemplate>
              <Logout />
            </AuthenticatedTemplate>
          }
        />
        <Route path="/404" element={<NotFoundView />} />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
      <SnackbarQueue />
    </PageLayoutDiv>
  );
}
