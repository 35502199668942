import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function GroupsTabStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The Groups section is used to display selected access group members, administrators, and relationships to other access groups.
      </Paragraph>
    </StepContentDiv>
  );
}
