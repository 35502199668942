import SearchIcon from "@mui/icons-material/Search";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
    selectCurrentAdminsSearch,
    setCurrentAdminsSearch,
    setIsSearchedAdminValidationError,
    setSearchedAdminEmail,
} from "../../../../app/slices/groupsSlice";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { SearchInputDiv } from "./SC/SearchInputDiv";
import { noAccessMessage } from "../AdminsGroupColumn";

interface AdminsSearchControlProps {
    minSearchLength: number;
    isDisabled?: boolean;
}

export function AdminsSearchControl(props: AdminsSearchControlProps) {
    const dispatch = useAppDispatch();
    const adminsSearchQuery = useAppSelector(selectCurrentAdminsSearch);

    const onSearch = useCallback(
        (search: string, hasError: boolean) => {
            if (search === "") {
                dispatch(setCurrentAdminsSearch(""));
                dispatch(setSearchedAdminEmail(""));
                return;
            }

            const trimmedSearch = search.trim().toLocaleLowerCase();
            if (!hasError && trimmedSearch.length >= props.minSearchLength) {
                dispatch(setSearchedAdminEmail(trimmedSearch));
            }
        },
        [dispatch, props.minSearchLength]
    );

    const onHandleChange = useCallback(
        (text: string) => {
            dispatch(setCurrentAdminsSearch(text));
        },
        [dispatch]
    );

    const onError = useCallback(
        (isError: boolean) => {
            dispatch(setIsSearchedAdminValidationError(isError));
        },
        [dispatch]
    );

    return (
        <SearchInputDiv id="admins-search-input-div">
            <TextFieldInput
                id={"admins-search-input"}
                label=""
                placeholder="Type search query (min. 3 chars)"
                onClick={onSearch}
                initialValue={adminsSearchQuery}
                trimEntries={false}
                actionText="Search"
                actionIcon={<SearchIcon />}
                onHandleChange={onHandleChange}
                onError={onError}
                isDisabled={props.isDisabled}
                disabledMessage={noAccessMessage}
            />
        </SearchInputDiv>
    );
}
