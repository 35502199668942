import { useEffect, useState } from "react";
import Joyride, { Actions, ACTIONS, CallBackProps, Status, STATUS, Step } from "react-joyride";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { selectCurrentSelfHelp, selectIsAnyAdmins, selectIsAnyCategories, selectIsAnyMemberOf, selectIsAnyMembers, selectUncompletedSections, setCurrentSelfHelp } from "../../app/slices/selfHelpSlice";
import { defaultTheme } from "../../app/theme";
import { getSelfHelpSteps } from "./helpers/selfHelpBuilder";
import { SelfHelpDiv } from "./SC/SelfHelpDiv";
import { SelfHelp, SelfHelpPage, SelfHelpSectionIdentifier } from "../../models/selfHelpType";
import { selectCurrentSearchAccessGroup, selectIsUserAdminInAnyAccessGroup } from "../../app/slices/groupsSlice";
import { selectIsSuperAdmin } from "../../app/slices/authenticationSlice";
import { useGetSelfHelp } from "./hooks/useGetSelfHelp";
import { useMarkSectionCompleted } from "./hooks/useMarkSectionCompleted";

export function SelfHelpProvider() {
  const dispatch = useAppDispatch();

  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);

  const currentSelfHelp = useAppSelector(selectCurrentSelfHelp);
  const isUserAdminInAnyAccessGroup = useAppSelector(selectIsUserAdminInAnyAccessGroup);
  const isAnyAdmins = useAppSelector(selectIsAnyAdmins);
  const isAnyMembers = useAppSelector(selectIsAnyMembers);
  const isAnyCategories = useAppSelector(selectIsAnyCategories);
  const isAnyMemberOf = useAppSelector(selectIsAnyMemberOf);

  const isAdminInCurrentGroup = isSuperAdmin || currentGroup?.hasAdminAccess;
  const isUserAdminInAnyGroup = isSuperAdmin || isUserAdminInAnyAccessGroup;

  const [steps, setSteps] = useState<Step[]>([]);
  const [sectionToMark, setSectionToMark] = useState<SelfHelpSectionIdentifier>();
  const { fetchUncompletedSelfHelps } = useGetSelfHelp();
  const { markSectionAsCompleted } = useMarkSectionCompleted();
  const isAnyRemoveButton = document.getElementsByClassName("delete-button").length > 0;
  const noUsersFound = document.getElementById("no-users-found-error-container") !== null;
  const noAdminsFound = document.getElementById("no-admins-found-error-container") !== null;
  const uncompletedSections = useAppSelector(selectUncompletedSections)
  useEffect(() => { fetchUncompletedSelfHelps(); }, [fetchUncompletedSelfHelps]);

  useEffect(() => {
    if (currentSelfHelp !== SelfHelp.None) {
      setSteps(getSelfHelpSteps(currentSelfHelp, isAdminInCurrentGroup, isUserAdminInAnyGroup, isAnyAdmins, isAnyMembers, isAnyCategories, isAnyMemberOf, isAnyRemoveButton, noUsersFound, noAdminsFound));
    } else {
      setSteps([]);
    }
  }, [currentSelfHelp, isAdminInCurrentGroup, isAnyAdmins, isAnyCategories, isAnyMemberOf, isAnyMembers, isAnyRemoveButton, isUserAdminInAnyGroup, noAdminsFound, noUsersFound]);

  useEffect(() => {
    if (sectionToMark) {
      markSectionAsCompleted(sectionToMark);
      setSectionToMark(undefined);
    }
  }, [sectionToMark, markSectionAsCompleted]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, step } = data;

    const finishedStatuses: Status[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const finishedActions: Actions[] = [ACTIONS.CLOSE];

    if (status === STATUS.FINISHED) {
      const section: SelfHelpSectionIdentifier = {
        page: SelfHelpPage.UserAccessManagement,
        sectionName: currentSelfHelp
      };
      if (uncompletedSections.find(
        (x) => x.sectionName === section.sectionName)
      ) {
        setSectionToMark(section);
      }
    }

    if (finishedStatuses.includes(status) || finishedActions.includes(action)) {
      dispatch(setCurrentSelfHelp(SelfHelp.None));
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const tgt = step !== null ? (step.target as string) : "";
    if (tgt) document.querySelector(tgt)?.scrollIntoView();
  };

  if (currentSelfHelp === SelfHelp.None) {
    return <SelfHelpDiv />;
  }

  return (
    <SelfHelpDiv>
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        run={true}
        styles={{
          options: {
            zIndex: 1000,
            primaryColor: defaultTheme.palettes.primary.main,
          },
          buttonBack: {
            color: defaultTheme.palettes.secondary.archived,
          },
        }}
        floaterProps={{
          disableAnimation: true,
        }}
        continuous={true}
        showProgress={true}
        disableScrollParentFix={true}
        disableScrolling={true}
      />
    </SelfHelpDiv>
  );
}
