import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";

interface StyledErrorIconProps {
  $paddingRight?: boolean;
}

export const StyledErrorIcon = styled(ErrorIcon) <StyledErrorIconProps>`
  vertical-align: middle;
  margin: 0px;
  padding-right: ${(props) => (props.$paddingRight ? "4px" : "0px")};
`;
