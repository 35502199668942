import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/memberOf/memberOf.png";

export function NoContentStep() {
    const theme = useTheme();
    return (
        <StepContentDiv>
            <Paragraph>
                Groups that can be managed by current user are in{" "}
                <span style={{ fontWeight: "bold" }}>
                    bold, black font
                </span>. Groups to which current user does not have administrator rights are{" "}
                <span style={{ color: theme.palettes.grayscale.medium }}>
                    greyed-out
                </span>.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot} />
            </Paragraph>
        </StepContentDiv>
    );
}
