import { InformationControl } from "../controls/InformationControl/InformationControl";
import { ContentDiv } from "./layout/SC/ContentDiv";
import { NotFoundDiv } from "./SC/NotFoundDiv";

export function NotFoundView() {
  return (
    <ContentDiv id="content-div">
      <NotFoundDiv>
        <InformationControl
          id={"page-not-found"}
          title={"Page Not Found (Error 404)"}
          description={
            "The page you are looking for can not be found or has been removed. Continue exploring"
          }
          $showDash={false}
          showReloadButton={false}
        />
      </NotFoundDiv>
    </ContentDiv>
  );
}
