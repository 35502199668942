import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import { ErrorDisplay } from "../../../controls/ErrorHandling/ErrorDisplay";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { PaperContentCenterDiv } from "../../../controls/Paper/SC/PaperContentCenterDiv";
import { PaperContentDiv } from "../../../controls/Paper/SC/PaperContentDiv";
import { GroupsColumnDiv } from "./SC/GroupsColumnDiv";
import { GroupsList } from "./Search/SC/GroupsList";
import { NoCategoriesFound } from "./NotFound/NoCategoriesFound";
import { Breadcrumb } from "./Braedcrumb/Breadcrumb";
import { SelfHelp } from "../../../models/selfHelpType";
import { useGetCategories } from "./hooks/Categories/useGetCategories";


function CategoriesGroupColumn() {
    const theme = useTheme();
    const { fetchCategories, categories, isCategoriesError, isCategoriesFetching } = useGetCategories();
    const getContent = () => {
        if (isCategoriesFetching) {
            return (
                <PaperContentCenterDiv>
                    <CircularProgress
                        sx={{ color: theme.palettes.primary.main }}
                        size="30px"
                    />
                </PaperContentCenterDiv>
            );
        }

        if (isCategoriesError) {
            return (
                <PaperContentCenterDiv>
                    <ErrorDisplay
                        errorId={"categories"}
                        errorMessageTitle={"Something went wrong"}
                        errorDescription={"Error while loading categories."}
                        refreshFunction={fetchCategories}
                        $showDash={false}
                        showReloadButton={true}
                        centerHeader={true}
                    />
                </PaperContentCenterDiv>
            );
        }

        if (categories?.length === 0) {
            return (
                <PaperContentCenterDiv id="categories-not-found-div">
                    <NoCategoriesFound />
                </PaperContentCenterDiv>
            );
        }

        return (
            <PaperContentDiv id="categories-content-div">
                <GroupsList id={"categories-list"} dense={true}>
                    {categories?.map((c) => (
                        <Breadcrumb
                            key={c.id}
                            categoryId={c.id}
                            isGray={false}
                            separator=">"
                            categories={c.breadcrumb}
                        />
                    ))}
                </GroupsList>
            </PaperContentDiv>
        );
    };

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    return (
        <GroupsColumnDiv width={100} height={50} id={"categories-column"}>
            <PaperControl title={"Categories"} selfHelp={SelfHelp.Categories}>{getContent()}</PaperControl>
        </GroupsColumnDiv>
    );
}

export default CategoriesGroupColumn;
