import DeleteIcon from "@mui/icons-material/Delete";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function RemoveStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        Administrators can remove a user from the group by clicking on the <DeleteIcon sx={{ verticalAlign: "bottom" }} /> icon.
      </Paragraph>
    </StepContentDiv>
  );
}
