import { Logo } from "./Logo/Logo";
import { HeaderDiv } from "./SC/HeaderDiv";
import { UserInfo } from "./UserInfo/UserInfo";

export function Header() {
  return (
    <HeaderDiv id="header-div">
      <Logo />
      <UserInfo />
    </HeaderDiv>
  );
}
