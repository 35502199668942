import { InformationControl } from "../../controls/InformationControl/InformationControl";

export function Instruction() {
  return (
    <InformationControl
      id={"group-info"}
      title="Start searching for a group"
      description="Search for the access group you would like to manage."
      $showDash={false}
    />
  );
}
