import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/menuBar/people_selector.png";

export function PeopleSelectorStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        When the People tab is opened, search for users is located here:
      </Paragraph>
      <Paragraph>
        <Screenshot src={screenshot} />
      </Paragraph>
      <Paragraph>
        Only full email address can be used to search for People to check their membership in access groups.
      </Paragraph>
    </StepContentDiv>
  );
}
