import { useAppSelector } from "../../app/hooks/hooks";
import { selectCurrentPerson } from "../../app/slices/peopleSlice";
import { TabDiv } from "../../pages/layout/SC/TabDiv";
import { Instruction } from "./Instruction";
import { PersonControl } from "./PersonControl";

export function PeopleTab() {
  const person = useAppSelector(selectCurrentPerson);

  return (
    <TabDiv id="people-tab-div">
      {person !== null && <PersonControl person={person} />}
      {person === null && <Instruction />}
    </TabDiv>
  );
}
