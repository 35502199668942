import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "./baseQueries";
import { ApiRequest } from "../../models/apiRequest";
import {
  SelfHelpSection,
  SelfHelpSectionIdentifier,
} from "../../models/selfHelpType";

export const selfHelpApi = createApi({
  reducerPath: "selfHelpApi",
  baseQuery: apiBaseQuery,
  endpoints: (builder) => ({
    markSectionAsCompleted: builder.mutation<
      boolean,
      ApiRequest & SelfHelpSectionIdentifier
    >({
      query: (params: ApiRequest & SelfHelpSectionIdentifier) => ({
        url: `api/v${params.version}/Service/SelfHelp/${params.page}/${params.sectionName}`,
        method: "POST",
      }),
    }),
    getUncompletedSelfHelp: builder.query<SelfHelpSection[], ApiRequest>({
      query: (request: ApiRequest) => ({
        url: `api/v${request.version}/Service/SelfHelp`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetUncompletedSelfHelpQuery,
  useMarkSectionAsCompletedMutation,
} = selfHelpApi;
export default selfHelpApi.reducer;
