import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/menuBar/group_selector_big.png";

export function GroupSelectorStep() {
  const theme = useTheme();
  return (
    <StepContentDiv>
      <Paragraph>
        When the Groups tab is opened, choosing access groups to manage/view is located here.
      </Paragraph>
      <Paragraph>
        <Screenshot src={screenshot} />
      </Paragraph>
      <Paragraph>
        Access group to manage/view are selected from a dropdown list.
      </Paragraph>
      <Paragraph>
        <span style={{ fontWeight: "bold" }}>
          Groups in bold, black font
        </span>{" "} at the beginning of the list can be managed by current user.
      </Paragraph>
      <Paragraph>
        <span style={{ color: theme.palettes.grayscale.medium }}>
          Greyed-out
        </span>{" "} groups can only be used to check their administrators.
      </Paragraph>
      <Paragraph>
        Search looks for typed in query in the whole access group name.
      </Paragraph>
    </StepContentDiv>
  );
}
