import SearchIcon from "@mui/icons-material/Search";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectCurrentMembersSearch,
  setCurrentMembersSearch,
  setIsSearchedMemberValidationError,
  setSearchedMemberEmail,
} from "../../../../app/slices/groupsSlice";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { emailRegex } from "../../../../helpers/emailHelper";
import { SearchInputDiv } from "./SC/SearchInputDiv";

interface MembersSearchControlProps {
  isDisabled: boolean;
}

export function MembersSearchControl(props: MembersSearchControlProps) {
  const dispatch = useAppDispatch();
  const membersSearchQuery = useAppSelector(selectCurrentMembersSearch);

  const onSearch = useCallback(
    (search: string, hasError: boolean) => {
      if (search === "") {
        dispatch(setCurrentMembersSearch(""));
        dispatch(setSearchedMemberEmail(""));
        return;
      }

      const trimmedSearch = search.trim().toLocaleLowerCase();
      if (!hasError) {
        dispatch(setSearchedMemberEmail(trimmedSearch));
      }
    },
    [dispatch]
  );

  const onHandleChange = useCallback(
    (text: string) => {
      dispatch(setCurrentMembersSearch(text));
    },
    [dispatch]
  );

  const onError = useCallback(
    (isError: boolean) => {
      dispatch(setIsSearchedMemberValidationError(isError));
    },
    [dispatch]
  );

  return (
    <SearchInputDiv id="members-search-input-div">
      <TextFieldInput
        id={"members-search-input"}
        label=""
        placeholder="Type user email"
        onClick={onSearch}
        initialValue={membersSearchQuery}
        trimEntries={false}
        actionText="Search"
        actionIcon={<SearchIcon />}
        onHandleChange={onHandleChange}
        validationMsg="Invalid email address"
        validationRegExp={emailRegex}
        onError={onError}
        isDisabled={props.isDisabled}
      />
    </SearchInputDiv>
  );
}
