import { ReactNode } from "react";
import { PaperHeaderDiv } from "./SC/PaperHeaderDiv";
import { StyledPaper } from "./SC/StyledPaper";
import { SelfHelp } from "../../models/selfHelpType";
import { SelfHelpButtonControl } from "../Buttons/SelfHelpButtonControl";
import { PaperHeaderTitleSpan } from "./SC/PaperHeaderTitleSpan";

interface PaperControlProps {
  children: ReactNode;
  title: string;
  selfHelp: SelfHelp;
}

export function PaperControl(props: PaperControlProps) {
  return (
    <StyledPaper elevation={2}>
      <PaperHeaderDiv>
        <PaperHeaderTitleSpan>{props.title}</PaperHeaderTitleSpan>
        <SelfHelpButtonControl currentSelfHelp={props.selfHelp} />
      </PaperHeaderDiv>
      {props.children}
    </StyledPaper>
  );
}
