import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
import { useTheme } from "styled-components";
import { ErrorContainerDiv } from "./SC/ErrorContainerDiv";
import { ErrorBoxDiv } from "./SC/ErrorBoxDiv";
import { ErrorHeaderDiv } from "./SC/ErrorHeaderDiv";
import { ErrorMessage } from "./SC/ErrorMessage";
import { DashDiv } from "./SC/DashDiv";
import { ErrorContainerProps } from "./ErrorDisplay";
import { ReloadContainerDiv } from "./SC/ReloadContainerDiv";

interface ErrorDisplayWrapperProps {
  errorMessageTitle?: string;
  children: React.ReactNode;
  showReloadButton?: boolean;
  centerHeader?: boolean;
  errorContainerProps?: ErrorContainerProps;
  errorBoxPadding?: string;
  errorBoxAlignItems?: string;
  $showDash?: boolean;
  refreshFunction?: () => void;
  errorId: string;
}

export const ErrorDisplayWrapper = ({
  errorMessageTitle,
  children,
  errorContainerProps,
  errorBoxPadding,
  showReloadButton = false,
  $showDash = true,
  errorId,
  refreshFunction = () => window.location.reload(),
  centerHeader = false,
}: ErrorDisplayWrapperProps) => {
  const theme = useTheme();

  return (
    <ErrorContainerDiv
      $padding={errorContainerProps?.padding}
      $position={errorContainerProps?.position}
      $transform={errorContainerProps?.transform}
      $top={errorContainerProps?.top}
      $left={errorContainerProps?.left}
      id={`${errorId}-error-container`}
    >
      <ErrorBoxDiv $padding={errorBoxPadding} id={`${errorId}-error-box`}>
        <ErrorHeaderDiv $centerText={centerHeader}>
          {errorMessageTitle}
        </ErrorHeaderDiv>
        {$showDash && <DashDiv />}
        <ErrorMessage id={`${errorId}-error-message`}>{children}</ErrorMessage>
        {showReloadButton && (
          <ReloadContainerDiv>
            <Button
              id={"refresh-button"}
              variant="contained"
              startIcon={<RefreshIcon fontSize="small" />}
              color="error"
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                backgroundColor: theme.palettes.primary.main,
              }}
              onClick={refreshFunction}
            >
              Reload
            </Button>
          </ReloadContainerDiv>
        )}
      </ErrorBoxDiv>
    </ErrorContainerDiv>
  );
};
