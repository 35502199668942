import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { StyledAlert } from "./SC/StyledAlert";
import { SnackbarType } from "../../models/snackbar";

export interface CustomSnackbarProps {
  message: string;
  type: SnackbarType;
  onClose: () => void;
}

export const CustomSnackbar = (props: CustomSnackbarProps) => {
  const [open, setOpen] = useState(true);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent<unknown> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    handleCloseAlert();
  };

  const handleCloseAlert = () => {
    setOpen(false);
    props.onClose();
  };

  const selectIcon = (type?: SnackbarType) => {
    if (type === SnackbarType.error) {
      return <CancelIcon fontSize="inherit" />;
    }

    if (type === SnackbarType.warning || type === SnackbarType.info) {
      return <InfoIcon fontSize="inherit" />;
    }

    return <CheckCircleIcon fontSize="inherit" />;
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <StyledAlert
        variant="filled"
        onClose={handleCloseAlert}
        color={props.type}
        icon={selectIcon(props.type)}
        sx={{
          borderRadius: 0,
          boxShadow: "0 6px 12px 0 rgba(15, 15, 15, 0.35)",
        }}
      >
        <div id="snackbar-message">{props.message}</div>
      </StyledAlert>
    </Snackbar>
  );
};
