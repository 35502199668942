import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
    selectUncompletedSections,
    setCurrentSelfHelp,
} from "../../app/slices/selfHelpSlice";
import { SelfHelp, SelfHelpSection, SelfHelpStatus } from "../../models/selfHelpType";
import { IconButtonControl } from "./SC/IconButtonControl";
import { StyledHelpIcon } from "./SC/StyledHelpIcon";

interface SelfHelpButtonControlProps {
    currentSelfHelp?: SelfHelp;
    disabled?: boolean;
    id?: string;
    onClickHandler?: (e: React.SyntheticEvent) => void;
}

export function SelfHelpButtonControl(props: SelfHelpButtonControlProps) {
    const dispatch = useAppDispatch();
    const uncompletedSelfHelps: SelfHelpSection[] = useAppSelector(selectUncompletedSections);

    const getSelfHelpColor = () => {
        const section = uncompletedSelfHelps.find(
            (u) => u.sectionName === props.currentSelfHelp
        );

        if (!section) return SelfHelpStatus.Completed;
        if (section.optional) return SelfHelpStatus.Optional;

        return SelfHelpStatus.Mandatory;
    };

    const handleSelfHelpClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (props.currentSelfHelp) {
            dispatch(setCurrentSelfHelp(props.currentSelfHelp));
        }
        if (props.onClickHandler) {
            props.onClickHandler(event);
        }
    };

    return (
        <IconButtonControl
            id={props.id}
            disabled={props.disabled}
            onClick={handleSelfHelpClick}
            popupText="Help"
            icon={<StyledHelpIcon $colorNew={getSelfHelpColor()} />}
        />
    );
}
