import { ListItemAvatar } from "@mui/material";
import { ItemAvatarDiv } from "./SC/ItemAvatarDiv";
import { StyledAvatar } from "./SC/StyledAvatar";
import { StyledListItem } from "./SC/StyledListItem";
import { StyledListItemTextVertical } from "./SC/StyledListItemTextVertical";
import { StyledPersonIcon } from "./SC/StyledPersonIcon";

export interface UserInfoProps {
  primaryInfo: string | null;
  secondaryInfo: string | null;
  width: string;
}

export function HeaderItemAvatar(props: UserInfoProps) {
  return (
    <ItemAvatarDiv width={props.width}>
      <StyledListItem>
        <ListItemAvatar>
          <StyledAvatar>
            <StyledPersonIcon />
          </StyledAvatar>
        </ListItemAvatar>
        <StyledListItemTextVertical
          primary={props.primaryInfo}
          secondary={props.secondaryInfo}
        />
      </StyledListItem>
    </ItemAvatarDiv>
  );
}
