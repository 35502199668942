import { createApi } from "@reduxjs/toolkit/query/react";
import { extractFullNameFromEmail } from "../../helpers/emailHelper";
import { AccessGroup } from "../../models/accessGroup";
import { ApiRequest } from "../../models/apiRequest";
import { Person } from "../../models/person";
import { RemoveFromGroupRequest } from "../../models/removeFromGroupRequest";
import { RemoveFromGroupResponse } from "../../models/removeFromGroupResponse";
import { PersonAccessGroup } from "../slices/peopleSlice";
import { apiBaseQuery } from "./baseQueries";

export const accessGroupsUsersApi = createApi({
  reducerPath: "accessGroupsUsersApi",
  baseQuery: apiBaseQuery,
  tagTypes: ["UserAccessGroups"],
  endpoints: (builder) => ({
    getAccessGroupUsers: builder.query<
      Person[],
      ApiRequest & { accessGroupName: string; userEmail: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Managed/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/Users?userEmail=${request.userEmail}`,
        method: "GET",
      }),
      transformResponse: (response: string[]): Person[] =>
        response.map((x) => ({
          name: extractFullNameFromEmail(x),
          email: x,
        })),
      keepUnusedDataFor: 3600,
    }),
    removeUserFromAccessGroup: builder.mutation<
      RemoveFromGroupResponse,
      RemoveFromGroupRequest
    >({
      query: (request) => ({
        url: `api/v${request.version}/Managed/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/Users/${request.userEmail}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "UserAccessGroups", id: arg.userEmail },
      ],
    }),
    addUserToAccessGroup: builder.mutation<
      RemoveFromGroupResponse,
      ApiRequest & { accessGroupName: string; userEmail: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Managed/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/Users/${request.userEmail}`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "UserAccessGroups", id: arg.userEmail },
      ],
    }),
    getUserAccessGroups: builder.query<
      PersonAccessGroup[],
      ApiRequest & { userEmail: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Service/Users/${request.userEmail}/AccessGroups`,
        method: "GET",
      }),
      transformResponse: (response: string[]): PersonAccessGroup[] =>
        response.map((x) => ({ name: x })),
      keepUnusedDataFor: 3600,
      providesTags: (result, error, arg) => [
        { type: "UserAccessGroups", id: arg.userEmail },
      ],
    }),
    getAccessGroupParents: builder.query<
      AccessGroup[],
      ApiRequest & { accessGroupName: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Service/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/ParentAccessGroups`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useAddUserToAccessGroupMutation,
  useLazyGetAccessGroupUsersQuery,
  useRemoveUserFromAccessGroupMutation,
  useLazyGetUserAccessGroupsQuery,
  useLazyGetAccessGroupParentsQuery,
} = accessGroupsUsersApi;

export default accessGroupsUsersApi.reducer;
