import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function NoAdminStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                When current user is not the administrator of the group, an empty list is displayed to comply with privacy laws.
            </Paragraph>
        </StepContentDiv>
    );
}
