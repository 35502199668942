import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  useLazyGetProfilePhotoQuery,
  useLazyGetUserQuery,
} from "../apis/graphApi";
import {
  selectIsQaAuth,
  selectIsQaAuthUser,
  setUserEmail,
  setUserName,
  setUserPhoto,
} from "../slices/authenticationSlice";
import { useAppDispatch, useAppSelector } from "./hooks";

export function useLoggedInUser() {
  const dispatch = useAppDispatch();
  const { instance: msalInstance } = useMsal();
  const account = msalInstance.getActiveAccount();
  const isQaAuth = useAppSelector(selectIsQaAuth);
  const qaAuthUser = useAppSelector(selectIsQaAuthUser);

  const [
    getProfilePhoto,
    { data: photo, isUninitialized: isPhotoUninitialized },
  ] = useLazyGetProfilePhotoQuery();

  const [
    getUser,
    {
      data: user,
      isUninitialized: isUserUninitialized,
      isError: isFetchUserError,
    },
  ] = useLazyGetUserQuery();

  useEffect(() => {
    if (isQaAuth && qaAuthUser) {
      dispatch(setUserEmail(qaAuthUser));
      dispatch(setUserName(qaAuthUser));
    }
  }, [dispatch, account, isQaAuth, qaAuthUser]);

  useEffect(() => {
    if (account && isUserUninitialized) {
      void getUser(null);
    }
  }, [account, getUser, isUserUninitialized]);

  useEffect(() => {
    if (user) {
      dispatch(setUserEmail(user.mail));
      dispatch(setUserName(user.displayName));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (account && isPhotoUninitialized) {
      void getProfilePhoto(null);
    }
  }, [account, getProfilePhoto, isPhotoUninitialized]);

  useEffect(() => {
    dispatch(setUserPhoto(photo));
  }, [dispatch, photo]);

  return { isFetchUserError };
}
