import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccessGroup } from "../../models/accessGroup";
import { AccessGroupWithPermissions } from "../../models/accessGroupWithPermissions";
import { RootState } from "../store";

export interface AdminAccessGroup {
  name: string;
}

export interface GroupsState {
  currentSearchAccessGroup: AccessGroupWithPermissions | null;
  allAccessGroups: AccessGroup[];
  adminAccessGroups: AdminAccessGroup[];
  currentGroupsSearch: string;
  currentMembersSearch: string;
  currentAdminsSearch: string;
  searchedMemberEmail: string;
  isSearchedMemberValidationError: boolean;
  searchedAdminEmail: string;
  isSearchedAdminValidationError: boolean;
  refreshAccessGroupsAdmins: boolean;
  isAddAdminValidationError: boolean;
  currentAddAdminInput: string;
  currentAddUserInput: string;
  isAddUserValidationError: boolean;
  refreshAccessGroupsUsers: boolean;
}

const initialState: GroupsState = {
  allAccessGroups: [],
  adminAccessGroups: [],
  currentSearchAccessGroup: null,
  currentGroupsSearch: "",
  currentMembersSearch: "",
  searchedMemberEmail: "",
  isSearchedMemberValidationError: false,
  currentAdminsSearch: "",
  isSearchedAdminValidationError: false,
  searchedAdminEmail: "",
  refreshAccessGroupsAdmins: false,
  currentAddAdminInput: "",
  isAddAdminValidationError: false,
  currentAddUserInput: "",
  isAddUserValidationError: false,
  refreshAccessGroupsUsers: false,
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState: initialState,
  reducers: {
    setCurrentSearchAccessGroup(
      state,
      action: PayloadAction<AccessGroupWithPermissions | null>
    ) {
      state.currentSearchAccessGroup = action.payload;
    },
    setAdminAccessGroups(state, action: PayloadAction<AdminAccessGroup[]>) {
      state.adminAccessGroups = action.payload;
    },
    setAllAccessGroups(state, action: PayloadAction<AccessGroup[]>) {
      state.allAccessGroups = action.payload;
    },
    setCurrentGroupsSearch(state, action: PayloadAction<string>) {
      state.currentGroupsSearch = action.payload;
    },
    setCurrentMembersSearch(state, action: PayloadAction<string>) {
      state.currentMembersSearch = action.payload;
    },
    setSearchedMemberEmail(state, action: PayloadAction<string>) {
      state.searchedMemberEmail = action.payload;
    },
    setIsSearchedMemberValidationError(state, action: PayloadAction<boolean>) {
      state.isSearchedMemberValidationError = action.payload;
    },
    setCurrentAdminsSearch(state, action: PayloadAction<string>) {
      state.currentAdminsSearch = action.payload;
    },
    setSearchedAdminEmail(state, action: PayloadAction<string>) {
      state.searchedAdminEmail = action.payload;
    },
    setIsSearchedAdminValidationError(state, action: PayloadAction<boolean>) {
      state.isSearchedAdminValidationError = action.payload;
    },
    setRefreshAccessGroupsAdmins(state, action: PayloadAction<boolean>) {
      state.refreshAccessGroupsAdmins = action.payload;
    },
    setIsAddAdminValidationError(state, action: PayloadAction<boolean>) {
      state.isAddAdminValidationError = action.payload;
    },
    setCurrentAddAdminInput(state, action: PayloadAction<string>) {
      state.currentAddAdminInput = action.payload;
    },
    setIsAddUserValidationError(state, action: PayloadAction<boolean>) {
      state.isAddUserValidationError = action.payload;
    },
    setCurrentAddUserInput(state, action: PayloadAction<string>) {
      state.currentAddUserInput = action.payload;
    },
    setRefreshAccessGroupsUsers(state, action: PayloadAction<boolean>) {
      state.refreshAccessGroupsUsers = action.payload;
    },
    clearInputs(state) {
      state.currentAddAdminInput = "";
      state.currentAddUserInput = "";
      state.isAddAdminValidationError = false;
      state.isAddUserValidationError = false;
      state.isSearchedAdminValidationError = false;
      state.isSearchedMemberValidationError = false;
      state.currentAdminsSearch = "";
      state.currentMembersSearch = "";
      state.searchedAdminEmail = "";
      state.searchedMemberEmail = "";
    },
  },
});

export const {
  setCurrentSearchAccessGroup,
  setAdminAccessGroups,
  setAllAccessGroups,
  setCurrentGroupsSearch,
  setCurrentMembersSearch,
  setSearchedMemberEmail,
  setIsSearchedMemberValidationError,
  setCurrentAdminsSearch,
  setIsSearchedAdminValidationError,
  setSearchedAdminEmail,
  setRefreshAccessGroupsAdmins,
  setCurrentAddAdminInput,
  setIsAddAdminValidationError,
  setCurrentAddUserInput,
  setIsAddUserValidationError,
  setRefreshAccessGroupsUsers,
  clearInputs,
} = groupsSlice.actions;

export const selectCurrentSearchAccessGroup = (state: RootState) =>
  state.groups.currentSearchAccessGroup;
export const selectAdminAccessGroups = (state: RootState) =>
  state.groups.adminAccessGroups;
export const selectIsUserAdminInAnyAccessGroup = (state: RootState) =>
  state.groups.adminAccessGroups.length > 0;
export const selectAllAccessGroups = (state: RootState) =>
  state.groups.allAccessGroups;
export const selectCurrentGroupsSearch = (state: RootState) =>
  state.groups.currentGroupsSearch;
export const selectCurrentMembersSearch = (state: RootState) =>
  state.groups.currentMembersSearch;
export const selectSearchedMemberEmail = (state: RootState) =>
  state.groups.searchedMemberEmail;
export const selectIsSearchedMemberValidationError = (state: RootState) =>
  state.groups.isSearchedMemberValidationError;
export const selectCurrentAdminsSearch = (state: RootState) =>
  state.groups.currentAdminsSearch;
export const selectSearchedAdminEmail = (state: RootState) =>
  state.groups.searchedAdminEmail;
export const selectIsSearchedAdminValidationError = (state: RootState) =>
  state.groups.isSearchedAdminValidationError;
export const selectIsAddAdminValidationError = (state: RootState) =>
  state.groups.isAddAdminValidationError;
export const selectCurrentAddAdminInput = (state: RootState) =>
  state.groups.currentAddAdminInput;
export const selectRefreshAccessGroupsAdmins = (state: RootState) =>
  state.groups.refreshAccessGroupsAdmins;
export const selectIsAddUserValidationError = (state: RootState) =>
  state.groups.isAddUserValidationError;
export const selectCurrentAddUserInput = (state: RootState) =>
  state.groups.currentAddUserInput;
export const selectRefreshAccessGroupsUsers = (state: RootState) =>
  state.groups.refreshAccessGroupsUsers;

export default groupsSlice.reducer;
