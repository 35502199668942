
import { useAppSelector } from "../../app/hooks/hooks";
import { selectForbiddenErrorData } from "../../app/slices/apiErrorSlice";
import { ErrorDisplay } from "./ErrorDisplay";
import { ErrorDescriptionDiv } from "./SC/ErrorDescriptionDiv";

export const UnauthorizedPage = () => {
  const error = useAppSelector(selectForbiddenErrorData);
  const userEmail = error.userEmail;

  return (
    <ErrorDisplay
      errorId={"screen-for-forbidden"}
      errorMessageTitle={`Authorization error`}
    >
      <ErrorDescriptionDiv>
        User with email {userEmail} is not authorized to access this
        application
      </ErrorDescriptionDiv>
    </ErrorDisplay>
  );
};
