import { MenuItem } from "@mui/material";
import styled from "styled-components";

export const StyledMenuItem = styled(MenuItem)`
  width: 180px;

  &:hover {
    color: ${(props) => props.theme.palettes.primary.main};
    cursor: pointer;
  }
`;
