import { useCallback, useEffect } from "react";
import { useLazyGetAccessGroupAdminsQuery } from "../../../../../app/apis/accessGroupsAdminsApi";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import useApiErrorHandling from "../../../../../app/hooks/useApiErrorHandling";
import {
  selectCurrentSearchAccessGroup,
  selectSearchedAdminEmail,
} from "../../../../../app/slices/groupsSlice";
import { setIsAnyAdmins } from "../../../../../app/slices/selfHelpSlice";

export function useGetAdmins() {
  const searchQuery = useAppSelector(selectSearchedAdminEmail);
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const dispatch = useAppDispatch();

  const [
    getAccessGroupAdmins,
    {
      data: users,
      isError: isAdminsError,
      error,
      isFetching: isAdminsFetching,
    },
  ] = useLazyGetAccessGroupAdminsQuery();
  useApiErrorHandling({
    isError: isAdminsError,
    error,
    customErrorMessage: "Error while fetching access group admins",
  });

  const fetchAdmins = useCallback(() => {
    if (currentGroup?.name) {
      void getAccessGroupAdmins(
        {
          version: 1,
          accessGroupName: currentGroup.name,
          searchQuery: searchQuery,
        },
        false
      );
    }
  }, [currentGroup?.name, searchQuery, getAccessGroupAdmins]);

  useEffect(() => {
    if (users && users.length > 0) {
      dispatch(setIsAnyAdmins(true));
    } else {
      dispatch(setIsAnyAdmins(false));
    }

  }, [users, dispatch])

  return { fetchAdmins, users, isAdminsFetching, isAdminsError };
}
