import { InformationControl } from "../../controls/InformationControl/InformationControl";

export function Instruction() {
  return (
    <InformationControl
      id={"people-info"}
      title="Start searching for a user"
      description="Search for the users for whom you want to see the access groups assigned to them."
      $showDash={false}
    />
  );
}
