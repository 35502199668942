import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/people/groups.png";

export function SectionStep() {
  const theme = useTheme();
  return (
    <StepContentDiv>
      <Paragraph>
        Once the user that was searched for is found, their membership will be displayed in this section.
      </Paragraph>
      <Paragraph>
        <span style={{ fontWeight: "bold" }}>
          Groups in bold, black font
        </span>{" "} at the beginning of the list can be managed by current administrator.

      </Paragraph>
      <Paragraph>  <span style={{ color: theme.palettes.grayscale.medium }}>
        Greyed-out
      </span>{" "}
        groups can only be used to check their administrators.</Paragraph>
      <Paragraph>
        Clicking on the group name will open Groups tab for that group.
      </Paragraph>
      <Paragraph>
        <Screenshot src={screenshot} />
      </Paragraph>
    </StepContentDiv>
  );
}
