import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                The Members section is used to display members and sub-groups of the selected group. It shows members only for access group administrators.
            </Paragraph>
            <Paragraph>
                Member is a person who belongs to an access group and, based on that membership, can have access to additional documents assigned to the group.
            </Paragraph>
        </StepContentDiv>
    );
}
