import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "./baseQueries";
import { ApiRequest } from "../../models/apiRequest";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: apiBaseQuery,
  endpoints: (builder) => ({
    getUserRoles: builder.query<string[], ApiRequest>({
      query: (request: ApiRequest) => ({
        url: `api/v${request.version}/Service/Roles`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetUserRolesQuery } = usersApi;
export default usersApi.reducer;
