import styled from "styled-components";

interface MainContentDivProps {
  $isVisible: boolean;
}

export const MainContentDiv = styled.div<MainContentDivProps>`
  width: inherit;
  height: ${(props) => (props.$isVisible ? "calc(100% - 24px)" : "0")};
  visibility: ${(props) => (props.$isVisible ? "visible" : "hidden")};
`;
