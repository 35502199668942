import { useCallback } from "react";
import { selectCurrentAddAdminInput, setCurrentAddAdminInput, setIsAddAdminValidationError } from "../../../../app/slices/groupsSlice";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { emailRegex } from "../../../../helpers/emailHelper";
import { noAccessMessage } from "../AdminsGroupColumn";
import { AddInputDiv } from "./SC/AddInputDiv";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";

interface AddControlProps {
    marginTop?: number;
    isDisabled?: boolean;
    onAddAdmin: (userEmail: string | null) => void
}

export function AddAdminControl(props: AddControlProps) {
    const dispatch = useAppDispatch()
    const searchText = useAppSelector(selectCurrentAddAdminInput)

    const onHandleChange = useCallback(
        (text: string) => {
            dispatch(setCurrentAddAdminInput(text));
        },
        [dispatch]
    );

    const onError = useCallback(
        (isError: boolean) => {
            dispatch(setIsAddAdminValidationError(isError));
        },
        [dispatch]
    );

    const onAddAdmin = useCallback(
        (search: string, hasError: boolean) => {
            if (search === "") {
                dispatch(setCurrentAddAdminInput(""));
                return;
            }

            if (!hasError) {
                props.onAddAdmin(search);
            }
        },
        [dispatch, props]
    );

    return (
        <AddInputDiv id="add-new-administrator-input-div" $marginTop={props.marginTop}>
            <TextFieldInput
                id={"add-new-administrator-input"}
                label=""
                placeholder="Add new administrator..."
                onClick={onAddAdmin}
                onHandleChange={onHandleChange}
                onError={onError}
                initialValue={searchText}
                trimEntries={false}
                actionText="Add new administrator"
                actionIcon={<AddIcon />}
                isDisabled={props.isDisabled}
                disabledMessage={noAccessMessage}
                validationMsg="Invalid email address"
                validationRegExp={emailRegex}
            />
        </AddInputDiv>
    );
}
