import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function CategoriesStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                In this section, a list of all categories where the selected group can be assigned to a document is displayed.
            </Paragraph>
            <Paragraph>
                The Category Owner can add or remove available access groups for the owned categories in the Settings.
            </Paragraph>
        </StepContentDiv>
    );
}
