import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/members/user_search.png";

export function UserSearchGeneralStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                In this section a group administrator can search for members of the group using their full email address.
                When current user is not the administrator of the selected group, will not see this field.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot} />
            </Paragraph>
        </StepContentDiv>
    );
}
