import { useCallback, useEffect } from "react";
import { useLazyGetAccessGroupUsersQuery } from "../../../../../app/apis/accessGroupsUsersApi";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import useApiErrorHandling from "../../../../../app/hooks/useApiErrorHandling";
import { selectIsSuperAdmin } from "../../../../../app/slices/authenticationSlice";
import {
  selectCurrentSearchAccessGroup,
  selectSearchedMemberEmail,
} from "../../../../../app/slices/groupsSlice";
import { setIsAnyMembers } from "../../../../../app/slices/selfHelpSlice";

export function useGetUsers() {
  const searchedMemberEmail = useAppSelector(selectSearchedMemberEmail);
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);
  const canAdministrate = isSuperAdmin || currentGroup?.hasAdminAccess;
  const dispatch = useAppDispatch();

  const [
    getAccessGroupsUsers,
    { data: users, isError: isUsersError, error, isFetching: isUsersFetching },
  ] = useLazyGetAccessGroupUsersQuery();
  useApiErrorHandling({
    isError: isUsersError,
    error,
    customErrorMessage: "Error while fetching access group users",
  });

  const fetchUsers = useCallback(() => {
    if (canAdministrate && currentGroup) {
      void getAccessGroupsUsers(
        {
          version: 1,
          accessGroupName: currentGroup.name,
          userEmail: searchedMemberEmail,
        },
        false
      );
    }
  }, [
    canAdministrate,
    currentGroup,
    getAccessGroupsUsers,
    searchedMemberEmail,
  ]);

  useEffect(() => {
    if (users && users.length > 0) {
      dispatch(setIsAnyMembers(true));
    } else {
      dispatch(setIsAnyMembers(false));
    }

  }, [users, dispatch])

  return { fetchUsers, users, isUsersFetching, isUsersError };
}
