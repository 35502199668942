import { ErrorDisplayWrapper } from "./ErrorDisplayWrapper";
import { ErrorDescriptionDiv } from "./SC/ErrorDescriptionDiv";

export interface ErrorDisplayProps {
  errorId: string;
  errorMessageTitle?: string;
  errorContainerProps?: ErrorContainerProps;
  errorDescription?: string;
  errorBoxPadding?: string;
  $showDash?: boolean;
  showReloadButton?: boolean;
  centerHeader?: boolean;
  children?: React.ReactNode;
  refreshFunction?: () => void;
}

export interface ErrorContainerProps {
  padding?: string;
  position?: string;
  transform?: string;
  top?: string;
  left?: string;
}

export const ErrorDisplay = (props: ErrorDisplayProps) => {
  return (
    <ErrorDisplayWrapper
      {...props}
      showReloadButton={props.showReloadButton ?? false}
      $showDash={props.$showDash ?? false}
      centerHeader={props.centerHeader ?? false}
    >
      {props.children ?? (
        <ErrorDescriptionDiv>{props.errorDescription}</ErrorDescriptionDiv>
      )}
    </ErrorDisplayWrapper>
  );
};
