import { List } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useGetAppSettingsQuery } from "../../../app/apis/configApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { selectAdminAccessGroups } from "../../../app/slices/groupsSlice";
import {
  PersonAccessGroup,
  selectCurrentPersonAccessGroups,
  setCurrentPersonAccessGroups,
} from "../../../app/slices/peopleSlice";
import { ErrorDisplay } from "../../../controls/ErrorHandling/ErrorDisplay";
import { UserAccessGroupItemAvatar } from "../../../controls/ItemAvatar/UserAccessGroupItemAvatar";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { Person } from "../../../models/person";
import { useGetPersonAccessGroups } from "../hooks/useGetPersonAccessGroups";
import { AccessGroupDiv } from "./SC/AccessGroupDiv";
import { SelfHelp } from "../../../models/selfHelpType";
import { StyledFolderIcon } from "../../../controls/ItemAvatar/SC/StyledFolderIcon";

interface AccessGroupListProps {
  person: Person;
}

const sortByName = (a: PersonAccessGroup, b: PersonAccessGroup) => {
  const aLower = a.name.toLocaleLowerCase();
  const bLower = b.name.toLocaleLowerCase();
  return aLower.localeCompare(bLower);
};

export function AccessGroupList(props: AccessGroupListProps) {
  const dispatch = useAppDispatch();
  const { data: appSettings } = useGetAppSettingsQuery(null);
  const accessGroups = useAppSelector(selectCurrentPersonAccessGroups);
  const adminAccessGroups = useAppSelector(selectAdminAccessGroups);
  const adminAccessGroupsNames = useMemo(
    () => adminAccessGroups.map((x) => x.name),
    [adminAccessGroups]
  );
  const updatedAccessGroups = useRef(false);

  const onAccessGroupsFetch = useCallback(() => {
    updatedAccessGroups.current = true;
  }, []);
  const { personAccessGroups, fetchAccessGroups, isError, isLoading } =
    useGetPersonAccessGroups({
      email: props.person.email,
      onAccessGroupsFetch,
    });

  useEffect(() => {
    if (appSettings) {
      if (!personAccessGroups || !updatedAccessGroups.current) {
        return;
      }

      const serviceGroups = appSettings.serviceAccessGroups;
      const filteredAccessGroups = personAccessGroups.filter(
        (x) => !serviceGroups.includes(x.name)
      );

      const adminPersonGroups = filteredAccessGroups
        .filter((x) => adminAccessGroupsNames.includes(x.name))
        .sort(sortByName)
        .map(
          (x) =>
          ({
            name: x.name,
            currentUserHasAdminAccess: true,
          } as PersonAccessGroup)
        );
      const nonAdminGroups = filteredAccessGroups
        .filter((x) => !adminPersonGroups.some((y) => y.name === x.name))
        .sort(sortByName);

      dispatch(
        setCurrentPersonAccessGroups([...adminPersonGroups, ...nonAdminGroups])
      );

      updatedAccessGroups.current = false;
    }
  }, [personAccessGroups, adminAccessGroupsNames, appSettings, dispatch]);

  const onAccessGroupRemoval = useCallback(
    (accessGroup: PersonAccessGroup) => {
      const newAccessGroups = accessGroups?.filter(
        (x) => x.name !== accessGroup.name
      );
      dispatch(setCurrentPersonAccessGroups(newAccessGroups));
    },
    [dispatch, accessGroups]
  );

  if (isLoading && updatedAccessGroups.current) {
    return (
      <AccessGroupDiv id="access-group-list-div">
        <PaperControl title={"Member of"} selfHelp={SelfHelp.People}>
          <List dense={true}>
            {Array.from({ length: 5 }).map((_, i) => (
              <UserAccessGroupItemAvatar
                key={i}
                accessGroup={{
                  name: "",
                }}
                icon={<StyledFolderIcon />}
                isLoading={true}
                onAccessGroupRemoval={onAccessGroupRemoval}
                person={props.person}
              />
            ))}
          </List>
        </PaperControl>
      </AccessGroupDiv>
    );
  }

  if (isError) {
    return (
      <ErrorDisplay
        errorId={"access-group-list-div"}
        errorMessageTitle={"Something went wrong"}
        errorDescription={"Error while loading user access groups."}
        refreshFunction={fetchAccessGroups}
        $showDash={false}
        showReloadButton={true}
        centerHeader={true}
      />
    );
  }

  return (
    <AccessGroupDiv id="access-group-list-div">
      <PaperControl title={"Member of"} selfHelp={SelfHelp.People}>
        <List dense={true}>
          {accessGroups?.map((ag) => {
            return (
              <UserAccessGroupItemAvatar
                key={ag.name}
                accessGroup={ag}
                icon={<StyledFolderIcon />}
                isLoading={false}
                onAccessGroupRemoval={onAccessGroupRemoval}
                person={props.person}
              />
            );
          })}
        </List>
      </PaperControl>
    </AccessGroupDiv>
  );
}
