import { ListItemText } from "@mui/material";
import styled from "styled-components";

interface StyledAccessGroupNameLinkProps {
  $highlightBlack: boolean;
}

export const StyledAccessGroupName = styled(
  ListItemText
)<StyledAccessGroupNameLinkProps>`
  &.MuiListItemText-root {
    color: ${(props) =>
      props.$highlightBlack
        ? `${props.theme.palettes.primary.dark}`
        : `${props.theme.palettes.grayscale.medium}`};

    &:hover {
      color: ${(props) => props.theme.palettes.primary.main};
      cursor: pointer;
    }

    .MuiTypography-root {
      font-weight: ${(props) => (props.$highlightBlack ? "bold" : "normal")};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
