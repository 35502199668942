import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ApiErrorState {
  forbiddenErrorData: ForbiddenErrorData;
  isAuthError: boolean;
}

export interface ForbiddenErrorData {
  message: string;
  errorCode: ForbiddenErrorCode;
  userEmail: string;
  userCompany: string | undefined;
}

export enum ForbiddenErrorCode {
  ExternalUser = 106,

}

const initialState: ApiErrorState = {
  forbiddenErrorData: {} as ForbiddenErrorData,
  isAuthError: false,
};

export const apiErrorSlice = createSlice({
  name: "apiError",
  initialState: initialState,
  reducers: {
    setForbiddenError(
      state: ApiErrorState,
      action: PayloadAction<ForbiddenErrorData>
    ) {
      if (!state.isAuthError) {
        state.forbiddenErrorData = action.payload;
        state.isAuthError = true;
      }
    },
  },
});

export const { setForbiddenError } = apiErrorSlice.actions;

export const selectIsAuthError = (state: RootState) => state.apiError.isAuthError;
export const selectForbiddenErrorData = (state: RootState) => state.apiError.forbiddenErrorData;
export default apiErrorSlice.reducer;
