import { useCallback, useEffect } from "react";
import { useRemoveAdminFromAccessGroupMutation } from "../../../../../app/apis/accessGroupsAdminsApi";
import { openSnackbar } from "../../../../../app/helpers/snackbarHelpers";
import { SnackbarType } from "../../../../../models/snackbar";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import {
  selectCurrentSearchAccessGroup,
  setRefreshAccessGroupsAdmins,
} from "../../../../../app/slices/groupsSlice";
import useApiErrorHandling from "../../../../../app/hooks/useApiErrorHandling";

export function useRemoveAdmin() {
  const dispatch = useAppDispatch();
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const [
    removeAdminQuery,
    { isError, error, isLoading: isRemovingAdmin, isSuccess },
  ] = useRemoveAdminFromAccessGroupMutation();
  useApiErrorHandling({
    isError,
    error,
    customErrorMessage: "Error while removing admin rights",
  });

  const removeAdmin = useCallback(
    (userEmail: string | null) => {
      if (currentGroup && userEmail) {
        void removeAdminQuery({
          version: 1,
          accessGroupName: currentGroup.name,
          userEmail,
        });
      }
    },
    [currentGroup, removeAdminQuery]
  );

  useEffect(() => {
    if (isSuccess) {
      openSnackbar(
        dispatch,
        "Admin rights removed successfully",
        SnackbarType.success
      );

      dispatch(setRefreshAccessGroupsAdmins(true));
    }
  }, [dispatch, isSuccess]);

  return { removeAdmin, isRemovingAdmin };
}
