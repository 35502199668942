import SearchIcon from "@mui/icons-material/Search";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  selectPeopleSearchQuery,
  setCurrentPerson,
  setHasCurrentPersonValidationError,
  setPeopleSearchQuery,
  setPersonWasNotFound,
} from "../../app/slices/peopleSlice";
import {
  emailRegex,
  extractFullNameFromEmail,
} from "../../helpers/emailHelper";
import { TextFieldInput } from "../TextField/TextFieldInput";
import { SearchInputDiv } from "./SC/SearchInputDiv";

interface CategorySearchProps {
  id: string;
  minQueryLength: number;
}

export function UserSearchInput(props: CategorySearchProps) {
  const dispatch = useAppDispatch();
  const peopleSearchQuery = useAppSelector(selectPeopleSearchQuery);

  const onSearch = useCallback(
    (search: string, hasError: boolean) => {
      dispatch(setPersonWasNotFound(false));
      dispatch(setHasCurrentPersonValidationError(hasError));

      if (search === "") {
        dispatch(setPeopleSearchQuery(""));
        dispatch(setCurrentPerson(null));
        return;
      }

      const trimmedSearch = search.trim().toLocaleLowerCase();

      if (trimmedSearch.length >= props.minQueryLength) {
        const email = trimmedSearch;
        const name = extractFullNameFromEmail(trimmedSearch);
        dispatch(
          setCurrentPerson({
            email: email,
            name: name,
          })
        );
      }
    },
    [dispatch, props.minQueryLength]
  );

  const onHandleChange = (text: string) => {
    dispatch(setPeopleSearchQuery(text));
  };

  return (
    <SearchInputDiv id="user-email-search-div" $width={598}>
      <TextFieldInput
        id={props.id + "-search-textfield"}
        label=""
        placeholder="Type full user email address"
        onClick={onSearch}
        initialValue={peopleSearchQuery}
        trimEntries={false}
        actionText="Search"
        validationRegExp={emailRegex}
        validationMsg="Invalid email address"
        actionIcon={<SearchIcon />}
        allowClickOnError={true}
        onHandleChange={onHandleChange}
      />
    </SearchInputDiv>
  );
}
