import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import QaWrapper from "./QaWrapper";
import { useGetAppSettingsQuery } from "./app/apis/configApi";
import { getMsalInstance } from "./app/apis/msalInstance";
import { browserHistory, reactPlugin } from "./app/config/appInsights";
import { useAppSelector } from "./app/hooks/hooks";
import {
  authStorageWhitelist,
  selectIsQaAuth,
} from "./app/slices/authenticationSlice";
import { PageLayout } from "./pages/layout/PageLayout";
import { SelfHelpProvider } from "./components/SelfHelp/SelfHelpProvider";
import { selectIsAuthError } from "./app/slices/apiErrorSlice";
import { UnauthorizedPage } from "./components/errors/UnauthorizedPage";

export function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const { data: appSettings } = useGetAppSettingsQuery(null);
  const location = useLocation();
  const isQaAuth = useAppSelector(selectIsQaAuth);
  const isAuthError = useAppSelector(selectIsAuthError);

  useEffect(() => {
    const keys = Object.keys(window.localStorage);
    for (const key of keys) {
      if (!authStorageWhitelist.includes(key)) {
        window.localStorage.removeItem(key);
      }
    }
  }, []);

  useEffect(() => {
    if (!isInitialized && location.pathname !== "/qa_auth") {
      if (appSettings) {
        const appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: appSettings.instrumentationKey,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin],
            extensionConfig: {
              [reactPlugin.identifier]: { history: browserHistory },
            },
          },
        });
        appInsights.loadAppInsights();

        setIsInitialized(true);
      }
    }
  }, [appSettings, isInitialized, location]);

  if (location.pathname === "/qa_auth") {
    return <QaWrapper />;
  }

  if (isInitialized && appSettings) {
    const msalInstance = getMsalInstance(appSettings.msalConfig);

    if (isQaAuth) {
      return (
        <MsalProvider instance={msalInstance}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <PageLayout />
          </AppInsightsContext.Provider>
        </MsalProvider>
      );
    }

    if (isAuthError) {
      return <UnauthorizedPage />;
    }

    return (
      <MsalProvider instance={msalInstance}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
          >
            <PageLayout />
            <SelfHelpProvider />
          </MsalAuthenticationTemplate>
        </AppInsightsContext.Provider>
      </MsalProvider>
    );
  }

  return <></>;
}
