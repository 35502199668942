import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { SectionStep } from "./controls/SectionStep";
import { NoAccessAddStep } from "./controls/NoAccessAddStep";
import { AnyAccessAddStep } from "./controls/AnyAccessAddStep";
import { HasAccessAddStep } from "./controls/HasAccessAddStep";
import { NoContentNoAccessRemoveStep } from "./controls/NoContentNoAccessStep";
import { ContentNoAccessRemoveStep } from "./controls/ContentNoAccessRemoveStep";
import { ContentHasAccessRemoveStep } from "./controls/ContentHasAccessRemoveStep";
import { AdminRowStep } from "./controls/AdminRowStep";
import { AdminSearchStep } from "./controls/AdminSearchStep";

export const adminsSteps = (isAdminInCurrentGroup: boolean | undefined, isUserAdminInAnyAccessGroup: boolean, isAnyAdmins: boolean, noAdminsFound: boolean): Step[] => {
  const steps: Step[] = [];

  steps.push(createStep("#admins-column", <SectionStep />, 620, "bottom"));
  steps.push(createStep("#admins-search-input-div", <AdminSearchStep />, 620, "bottom"));

  //add
  if (!isUserAdminInAnyAccessGroup) {
    steps.push(createStep("#add-new-administrator-input-div", <NoAccessAddStep />, 620, "bottom"));
  }

  if (isUserAdminInAnyAccessGroup && !isAdminInCurrentGroup) {
    steps.push(createStep("#add-new-administrator-input-div", <AnyAccessAddStep />, 620, "bottom"));
  }

  if (isAdminInCurrentGroup) {
    steps.push(createStep("#add-new-administrator-input-div", <HasAccessAddStep />, 620, "bottom"));
  }

  //remove
  if (!isUserAdminInAnyAccessGroup && (!isAnyAdmins || noAdminsFound)) {
    steps.push(createStep("#admins-no-content-div", <NoContentNoAccessRemoveStep />, 620, "bottom"));
  }

  if (isAnyAdmins && !noAdminsFound && (!isUserAdminInAnyAccessGroup || !isAdminInCurrentGroup)) {
    steps.push(createStep(".admins-delete-button", <ContentNoAccessRemoveStep />, 620, "bottom"));
  }

  if (isAnyAdmins && !noAdminsFound && isAdminInCurrentGroup) {
    steps.push(createStep(".admins-delete-button", <ContentHasAccessRemoveStep />, 620, "bottom"));
  }

  if (isAnyAdmins && !noAdminsFound) {
    steps.push(createStep(".admins-dense-item-row", <AdminRowStep />, 620, "bottom"));
  }

  return steps;
};
