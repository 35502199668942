import styled from "styled-components";

export const TabDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-height: calc(100% - 24px);
  background-color: lightblue;
  background-color: ${(props) => props.theme.palettes.grayscale.lightest};
  border-top: 1px solid ${(props) => props.theme.palettes.grayscale.light};
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;
