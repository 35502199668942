import styled from "styled-components";

export const PaperContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow-y: auto;
  max-width: 100%;
  margin-top: 10px;
`;
