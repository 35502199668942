const emailRegexPattern =
  "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";
export const emailRegex = RegExp(emailRegexPattern, "i");
const truncateEmailRegex = RegExp("@.*$");
const alphaNumericRegex = RegExp(/^[\p{L}0-9]+$/iu);

function toTitleCase(str: string) {
  return [...str]
    .map((x, index) => {
      if (x === ".") {
        return " ";
      }

      if (index === 0 || !alphaNumericRegex.test(str[index - 1])) {
        return x.toLocaleUpperCase();
      }

      return x;
    })
    .join("");
}

export function extractFullNameFromEmail(email: string) {
  const truncated = email.replace(truncateEmailRegex, "");
  return toTitleCase(truncated);
}
