import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/categories/categories.png";

export function CategoriesGeneralStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                In this section, a list of all categories where the selected group can be assigned to a document is displayed.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot} />
            </Paragraph>
            <Paragraph>
                The Category Owner can add or remove available access groups for the owned categories in the Settings.
            </Paragraph>
        </StepContentDiv>
    );
}
