import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function NoAccessAddStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                Adding a new administrator is disabled for non-administrators.
            </Paragraph>
        </StepContentDiv>
    );
}
