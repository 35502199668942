import styled from "styled-components";

export const ErrorDescriptionDiv = styled.div`
  font-size: ${(props) =>
    props.theme.typography.fontSizes.headerT2}px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
`;
