import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { SectionStep } from "./controls/SectionStep";
import { NoAdminStep } from "./controls/NoAdminStep";
import { AddUserStep } from "./controls/AddUserStep";
import { UserSearchStep } from "./controls/UserSearchStep";
import { UserSearchGeneralStep } from "./controls/UserSearchGeneralStep";
import { AddUserGeneralStep } from "./controls/AddUserGeneralStep";
import { MembersStep } from "./controls/MembersStep";
import { MembersNotFoundStep } from "./controls/MembersNotFoundStep";
import { MembersGeneralStep } from "./controls/MembersGeneralStep";
import { RemoveUserGeneralStep } from "./controls/RemoveUserGeneralStep";

export const membersSteps = (isAdminInCurrentGroup: boolean | undefined, isUserAdminInAnyAccessGroup: boolean, isAnyMembers: boolean, noUsersFound: boolean): Step[] => {
  const steps: Step[] = [];
  steps.push(createStep("#group-members-div", <SectionStep />, 620, "right"));

  if (!isUserAdminInAnyAccessGroup) {
    steps.push(createStep("#no-access-group-members-div", <NoAdminStep />, 620, "right"));
  }

  if (isAdminInCurrentGroup) {
    steps.push(createStep("#members-search-input-div", <UserSearchStep />, 620, "right"));
    steps.push(createStep("#add-new-user-input-div", <AddUserStep />, 620, "right"));
  }

  if (!isAdminInCurrentGroup && isUserAdminInAnyAccessGroup) {
    steps.push(createStep("#no-access-group-members-div", <UserSearchGeneralStep />, 620, "right"));
    steps.push(createStep("#no-access-group-members-div", <AddUserGeneralStep />, 620, "right"));
  }

  if (isAdminInCurrentGroup) {
    if (isAnyMembers && !noUsersFound) {
      steps.push(createStep("#members-content-div", <MembersStep />, 620, "right"));
    } else {
      steps.push(createStep("#members-not-found-content-div", <MembersNotFoundStep />, 620, "right"));
    }
  }

  if (!isAdminInCurrentGroup && isUserAdminInAnyAccessGroup) {
    steps.push(createStep("#no-access-group-members-div", <MembersGeneralStep />, 620, "right"));
  }

  if (isUserAdminInAnyAccessGroup) {
    if (!isAdminInCurrentGroup) {
      steps.push(createStep("#no-access-group-members-div", <RemoveUserGeneralStep />, 620, "right"));
    } else if (isAnyMembers) {
      steps.push(createStep("#members-content-div", <RemoveUserGeneralStep />, 620, "right"));
    } else {
      steps.push(createStep("#members-not-found-content-div", <RemoveUserGeneralStep />, 620, "right"));
    }
  }

  return steps;
};
