import styled from "styled-components";

export const PageLayoutDiv = styled.div`
  flex-direction: column;
  min-height: 220px;
  min-width: 560px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.palettes.grayscale.white};
`;
