import styled from "styled-components";

const StyledSpan = styled.span`
  display: flex;
  justify-content: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
  color: ${(props) => props.theme.palettes.grayscale.darkest};
`;

export default StyledSpan;
