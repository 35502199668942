import { Avatar, IconButton } from "@mui/material";

import { useState } from "react";
import { noAccessMessage } from "../../components/Groups/GroupColumn/AdminsGroupColumn";
import { DialogControl } from "../Dialogs/DialogControl";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { StyledListItem } from "./SC/StyledListItem";
import { StyledListItemAvatar } from "./SC/StyledListItemAvatar";
import { StyledListItemText } from "./SC/StyledListItemText";
import { StyledFolderIcon } from "./SC/StyledFolderIcon";
import { StyledPersonIcon } from "./SC/StyledPersonIcon";
import { StyledDeleteIcon } from "./SC/StyledDeleteIcon";

interface ItemAvatarProps {
  id: string;
  origin: string;
  primaryInfo: string;
  secondaryInfo: string;
  type: "person" | "group";
  isBold: boolean;
  canAdministrate?: boolean;
  onClick: () => void;
  onDelete?: () => void;
}

export function DenseItemAvatar(props: ItemAvatarProps) {
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);

  const removeAdminFromGroup = () => {
    if (props.onDelete) {
      props.onDelete();
    }
    setIsRemovePopupOpen(false);
  };

  return (
    <StyledListItem
      id={props.id}
      className={props.origin + "-dense-item-row"}
      secondaryAction={
        props.type === "person" && (
          <TooltipTop
            title={
              props.canAdministrate
                ? "Click to remove admin rights"
                : noAccessMessage
            }
          >
            <span>
              <IconButton
                className={props.origin + "-delete-button"}
                edge="end"
                aria-label="delete"
                onClick={() => setIsRemovePopupOpen(true)}
                disabled={!props.canAdministrate}
              >
                <StyledDeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </TooltipTop>
        )
      }
    >
      <StyledListItemAvatar>
        <Avatar sx={{ width: 28, height: 28 }}>
          {props.type === "group" && (
            <StyledFolderIcon fontSize="small" />
          )}
          {props.type === "person" && (
            <StyledPersonIcon fontSize="small" />
          )}
        </Avatar>
      </StyledListItemAvatar>
      <StyledListItemText
        onClick={() => props.onClick()}
        primary={props.primaryInfo}
        secondary={props.secondaryInfo}
        $isBlackColor={props.canAdministrate}
        $isBold={props.isBold}
        $hasPointer={true}
      />
      <DialogControl
        id={"remove-admin"}
        isOpen={isRemovePopupOpen}
        useActions={true}
        title={"Remove admin from the access group"}
        content={`Do you confirm removing ${props.primaryInfo} from this access group?`}
        fullWidth={true}
        maxWidth={"sm"}
        onCloseClick={() => setIsRemovePopupOpen(false)}
        onConfirmClick={removeAdminFromGroup}
      />
    </StyledListItem>
  );
}
