import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { CategoriesStep } from "./controls/CategoriesStep";
import { CategoriesGeneralStep } from "./controls/CategoriesGeneralStep";

export const categoriesSteps = (isAnyCategories: boolean): Step[] => {
  const steps: Step[] = [];

  if (isAnyCategories) {
    steps.push(createStep("#categories-content-div", <CategoriesStep />, 620, "left"));
  }
  else {
    steps.push(createStep("#categories-not-found-div", <CategoriesGeneralStep />, 620, "left"));
  }

  return steps;
};
