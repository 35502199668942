import { Tabs } from "@mui/material";
import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { selectTabType, setTabType } from "../../app/slices/mainViewSlice";
import { resetPeopleTab } from "../../app/slices/peopleSlice";
import { GroupSearchInput } from "../../controls/SearchInput/GroupSearchInput";
import { UserSearchInput } from "../../controls/SearchInput/UserSearchInput";
import { MainViewTabType } from "../../models/MainViewTabType";
import { HeaderDiv } from "./SC/HeaderDiv";
import { StyledTab } from "./SC/StyledTab";
import { SelfHelp } from "../../models/selfHelpType";
import { SelectorDiv } from "./SC/SelectorDiv";
import { SelfHelpButtonControl } from "../../controls/Buttons/SelfHelpButtonControl";

export function MainViewTabs() {
  const dispatch = useAppDispatch();
  const tabsRef = useRef<HTMLDivElement>(null);
  const tabIndex = useAppSelector(selectTabType);

  const changeTab = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setTabType(newValue as MainViewTabType));
    dispatch(resetPeopleTab());
  };

  return (
    <HeaderDiv>
      <Tabs
        ref={tabsRef}
        value={tabIndex}
        onChange={changeTab}
        aria-label="wrapped label tabs example"
        TabIndicatorProps={{ style: { background: "secondary.main" } }}
        textColor="secondary"
      >
        <StyledTab
          id="groups-tab"
          label="Groups"
          value={MainViewTabType.Groups}
        />
        <StyledTab
          id="people-tab"
          label="People"
          value={MainViewTabType.People}
        />
      </Tabs>
      <SelectorDiv id="menu-bar-selector-div">

        {tabIndex === MainViewTabType.People && (
          <UserSearchInput id="people" minQueryLength={3} />
        )}
        {tabIndex === MainViewTabType.Groups && <GroupSearchInput />}
        <SelfHelpButtonControl currentSelfHelp={SelfHelp.MenuBar} />
      </SelectorDiv>
    </HeaderDiv>
  );
}
