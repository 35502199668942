import { useCallback } from "react";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { emailRegex } from "../../../../helpers/emailHelper";
import { AddInputDiv } from "./SC/AddInputDiv";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  selectCurrentAddUserInput,
  setCurrentAddUserInput,
  setIsAddUserValidationError,
} from "../../../../app/slices/groupsSlice";

interface AddControlProps {
  marginTop?: number;
  isDisabled?: boolean;
  onAddUser: (userEmail: string | null) => void;
}

export function AddUserControl(props: AddControlProps) {
  const dispatch = useAppDispatch();
  const searchText = useAppSelector(selectCurrentAddUserInput);

  const onHandleChange = useCallback(
    (text: string) => {
      dispatch(setCurrentAddUserInput(text));
    },
    [dispatch]
  );

  const onError = useCallback(
    (isError: boolean) => {
      dispatch(setIsAddUserValidationError(isError));
    },
    [dispatch]
  );

  const onAddUser = useCallback(
    (search: string, hasError: boolean) => {
      if (search === "") {
        dispatch(setCurrentAddUserInput(""));
        return;
      }

      if (!hasError) {
        props.onAddUser(search);
      }
    },
    [dispatch, props]
  );

  return (
    <AddInputDiv id="add-new-user-input-div" $marginTop={props.marginTop}>
      <TextFieldInput
        id={"add-new-user-input"}
        label=""
        placeholder="Add new user..."
        onClick={onAddUser}
        onHandleChange={onHandleChange}
        onError={onError}
        initialValue={searchText}
        trimEntries={false}
        actionText="Add new user"
        actionIcon={<AddIcon />}
        isDisabled={props.isDisabled}
        validationMsg="Invalid email address"
        validationRegExp={emailRegex}
      />
    </AddInputDiv>
  );
}
