import { useAppSelector } from "../../app/hooks/hooks";
import { selectCurrentSearchAccessGroup } from "../../app/slices/groupsSlice";
import { TabDiv } from "../../pages/layout/SC/TabDiv";
import { GroupsControl } from "./GroupsControl";
import { Instruction } from "./Instruction";

export function GroupsTab() {
  const accessGroup = useAppSelector(selectCurrentSearchAccessGroup);

  return (
    <TabDiv id="groups-tab-div">
      {accessGroup !== null && <GroupsControl accessGroup={accessGroup} />}
      {accessGroup === null && <Instruction />}
    </TabDiv>
  );
}
