import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Person } from "../../models/person";
import { RootState } from "../store";

export interface PersonAccessGroup {
  name: string;
  currentUserHasAdminAccess?: boolean;
}

export interface PeopleState {
  person: Person | null;
  personWasNotFound: boolean;
  personAccessGroups?: PersonAccessGroup[];
  hasValidationError: boolean;
  searchQuery: string;
  selectedAccessGroup?: PersonAccessGroup;
}

const initialPeopleState: PeopleState = {
  person: null,
  personWasNotFound: false,
  searchQuery: "",
  hasValidationError: false,
};

export const peopleSlice = createSlice({
  name: "people",
  initialState: initialPeopleState,
  reducers: {
    setCurrentPerson(state, action: PayloadAction<Person | null>) {
      if (
        action.payload?.email !== state.person?.email ||
        action.payload?.name !== state.person?.name
      ) {
        state.personAccessGroups = undefined;
      }

      state.person = action.payload;
    },
    setPersonWasNotFound(state, action: PayloadAction<boolean>) {
      state.personWasNotFound = action.payload;
    },
    setCurrentPersonAccessGroups(
      state,
      action: PayloadAction<PersonAccessGroup[] | undefined>
    ) {
      state.personAccessGroups = action.payload;
    },
    setPeopleSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    setHasCurrentPersonValidationError(state, action: PayloadAction<boolean>) {
      state.hasValidationError = action.payload;
    },
    setSelectedPersonAccessGroup(
      state,
      action: PayloadAction<PersonAccessGroup | undefined>
    ) {
      state.selectedAccessGroup = action.payload;
    },
    resetPeopleTab(state) {
      if (state.hasValidationError || state.personWasNotFound) {
        state.person = null;
        state.personAccessGroups = undefined;
      }

      state.hasValidationError = false;
      state.personWasNotFound = false;
      state.searchQuery = "";
    },
  },
});

export const {
  setCurrentPerson,
  setPersonWasNotFound,
  setHasCurrentPersonValidationError,
  setCurrentPersonAccessGroups,
  setPeopleSearchQuery,
  setSelectedPersonAccessGroup,
  resetPeopleTab,
} = peopleSlice.actions;

export const selectCurrentPerson = (state: RootState) => state.people.person;
export const selectPersonWasNotFound = (state: RootState) =>
  state.people.personWasNotFound;
export const selectHasCurrentPersonValidationError = (state: RootState) =>
  state.people.hasValidationError;
export const selectCurrentPersonAccessGroups = (state: RootState) =>
  state.people.personAccessGroups;
export const selectPeopleSearchQuery = (state: RootState) =>
  state.people.searchQuery;
export const selectSelectedPersonAccessGroup = (state: RootState) =>
  state.people.selectedAccessGroup;

export default peopleSlice.reducer;
