import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

export function Logout() {
  const { instance: msalInstance } = useMsal();

  useEffect(() => {
    const account = msalInstance.getActiveAccount();
    void msalInstance.logoutRedirect({
      account: account,
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  }, [msalInstance]);

  return <div>Logging out</div>;
}
