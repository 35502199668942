import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import { RootState } from "../store";
import { configApi } from "./configApi";
import { getMsalInstance } from "./msalInstance";
import { ForbiddenErrorData, setForbiddenError } from "../slices/apiErrorSlice";

export const acquireToken = async (
  instance: PublicClientApplication,
  scopes: string[]
): Promise<string | undefined> => {
  try {
    await instance.initialize();
    const account = instance.getActiveAccount();
    if (account) {
      const authResponse: AuthenticationResult =
        await instance.acquireTokenSilent({ account, scopes });
      return authResponse.accessToken;
    } else {
      await instance.acquireTokenRedirect({
        scopes,
      });
    }
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect({
        scopes,
      });
    } else {
      console.log(e);
      // handling of other error
    }
  }

  return undefined;
};

export const apiBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  undefined | { setDetailsSplashScreen?: boolean }
> = async (args, api, extraOptions) => {
  const rootState = api.getState() as RootState;
  const appSettings =
    configApi.endpoints.getAppSettings.select(null)(rootState).data;

  if (!appSettings) {
    return {
      error: {
        status: 400,
        statusText: "Bad Request",
        data: "Application settings are missing",
      },
    };
  }

  let accessToken: string | undefined = undefined;
  const msalInstance = getMsalInstance(appSettings.msalConfig);
  const qaToken = rootState.authentication.qaAccessToken;

  if (!qaToken) {
    accessToken = await acquireToken(
      msalInstance,
      appSettings.userAccessManagementApi.scopes
    );
  }

  const baseQuery = fetchBaseQuery({
    baseUrl: appSettings.userAccessManagementApi.baseUrl,
    prepareHeaders: (headers) => {
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      const qaToken = rootState.authentication.qaAccessToken;
      if (qaToken) {
        headers.set("Authorization", `Bearer ${qaToken}`);
      }

      const qaAuth = rootState.authentication.qaAuthenticateAs;
      if (qaAuth) {
        headers.set("QaAuthentication", qaAuth);
      }

      const qaRoles = rootState.authentication.qaRoles;
      if (qaRoles) {
        headers.set("QaRoles", qaRoles);
      }

      return headers;
    },
    credentials: "include",
  });

  const result = await baseQuery(args, api, extraOptions ?? {});

  if (result.error && result.error.status === 403) {
    api.dispatch(setForbiddenError(result.error.data as ForbiddenErrorData));
  }

  if (result.error && result.error.status === 401) {
    void acquireToken(msalInstance, appSettings.userAccessManagementApi.scopes);
  }

  return result;
};

export const graphApiBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const rootState = api.getState() as RootState;
  const appSettings =
    configApi.endpoints.getAppSettings.select(null)(rootState).data;
  // gracefully handle scenarios where data to generate the URL is missing

  if (!appSettings) {
    return {
      error: {
        status: 400,
        statusText: "Bad Request",
        data: "Application settings are missing",
      },
    };
  }

  const qaToken = rootState.authentication.qaGraphApiAccessToken;
  let accessToken: string | undefined = undefined;
  if (!qaToken) {
    const msalInstance = getMsalInstance(appSettings.msalConfig);
    accessToken = await acquireToken(msalInstance, appSettings.graphApi.scopes);
  }

  return fetchBaseQuery({
    baseUrl: appSettings.graphApi.baseUrl,
    prepareHeaders: (headers) => {
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      const qaToken = rootState.authentication.qaGraphApiAccessToken;
      if (qaToken) {
        headers.set("Authorization", `Bearer ${qaToken}`);
      }

      return headers;
    },
  })(args, api, extraOptions);
};
