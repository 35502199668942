import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AdminSearchStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                This field is used to find the administrator on the list.
            </Paragraph>
        </StepContentDiv>
    );
}
