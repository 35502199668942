import { InformationControl } from "../../../../controls/InformationControl/InformationControl";

export function NoAccessToMembers() {
  return (
    <InformationControl
      id={"no-access-groups"}
      title="No administrator rights"
      description="This list is empty because You do not have administrator access to this access group."
      $showDash={false}
    />
  );
}
