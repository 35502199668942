import { useEffect, useMemo } from "react";
import { useLazyGetUserAdminAccessGroupsQuery } from "../app/apis/accessGroupsApi";
import { useLazyGetUserRolesQuery } from "../app/apis/usersApi";
import { openSnackbar } from "../app/helpers/snackbarHelpers";
import { useAppDispatch, useAppSelector } from "../app/hooks/hooks";
import { setUserRoles } from "../app/slices/authenticationSlice";
import { setAdminAccessGroups } from "../app/slices/groupsSlice";
import {
  selectIsWindowToSmall,
  selectTabType,
  selectUserAgreedForTooSmallWindow,
} from "../app/slices/mainViewSlice";
import { GroupsTab } from "../components/Groups/GroupsTab";
import { PeopleTab } from "../components/People/PeopleTab";
import { Footer } from "../components/footer/Footer";
import { MainViewTabs } from "../components/tabs/MainViewTabs";
import { ToSmallWindowScreenSplash } from "../controls/ScreenSplash/ToSmallWindowScreenSplash";
import { MainViewTabType } from "../models/MainViewTabType";
import { SnackbarType } from "../models/snackbar";
import { ContentDiv } from "./layout/SC/ContentDiv";
import { MainContentDiv } from "./layout/SC/MainContentDiv";

export function MainView() {
  const dispatch = useAppDispatch();
  const tabIndex = useAppSelector(selectTabType);
  const isWindowTooSmall = useAppSelector(selectIsWindowToSmall);
  const userAgreedForTooSmallWindow = useAppSelector(
    selectUserAgreedForTooSmallWindow
  );
  const [getCurrentUserAdminAccessGroups] =
    useLazyGetUserAdminAccessGroupsQuery();
  const [getUserRoles] = useLazyGetUserRolesQuery();
  const displayToSmallSplashScreen = useMemo(
    () => isWindowTooSmall && !userAgreedForTooSmallWindow,
    [isWindowTooSmall, userAgreedForTooSmallWindow]
  );

  useEffect(() => {
    getCurrentUserAdminAccessGroups({ version: 1 })
      .then((response) => {
        if (response.data) {
          dispatch(
            setAdminAccessGroups(
              response.data.map((x) => ({
                name: x,
              }))
            )
          );
        }
      })
      .catch(() => {
        openSnackbar(
          dispatch,
          "Error while loading user admin access groups",
          SnackbarType.error
        );
      });
  }, [getCurrentUserAdminAccessGroups, dispatch]);

  useEffect(() => {
    getUserRoles({ version: 1 })
      .then((response) => {
        if (response.data) {
          dispatch(setUserRoles(response.data));
        }
      })
      .catch(() => {
        openSnackbar(
          dispatch,
          "Error while loading user application roles",
          SnackbarType.error
        );
      });
  }, [getUserRoles, dispatch]);

  return (
    <ContentDiv id="main-view-content-div">
      <ToSmallWindowScreenSplash
        displaySplashScreen={displayToSmallSplashScreen}
      />
      <MainContentDiv $isVisible={!displayToSmallSplashScreen}>
        <MainViewTabs />
        {tabIndex === MainViewTabType.People && <PeopleTab />}
        {tabIndex === MainViewTabType.Groups && <GroupsTab />}
      </MainContentDiv>
      <Footer />
    </ContentDiv>
  );
}
