import { useCallback, useEffect } from "react";
import { useAddUserToAccessGroupMutation } from "../../../../../app/apis/accessGroupsUsersApi";
import { openSnackbar } from "../../../../../app/helpers/snackbarHelpers";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import useApiErrorHandling from "../../../../../app/hooks/useApiErrorHandling";
import {
  selectCurrentSearchAccessGroup,
  setCurrentAddUserInput,
  setRefreshAccessGroupsUsers,
} from "../../../../../app/slices/groupsSlice";
import { SnackbarType } from "../../../../../models/snackbar";

export function useAddUser() {
  const dispatch = useAppDispatch();
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);

  const [addUserQuery, { isError, error, isLoading: isAddingUser, isSuccess }] =
    useAddUserToAccessGroupMutation();
  useApiErrorHandling({
    isError,
    error,
    customErrorMessage: "Error while adding user to the access group",
  });

  const addUser = useCallback(
    (userEmail: string | null) => {
      if (currentGroup && userEmail) {
        void addUserQuery({
          version: 1,
          accessGroupName: currentGroup.name,
          userEmail,
        });
      }
    },
    [addUserQuery, currentGroup]
  );

  useEffect(() => {
    if (isSuccess) {
      openSnackbar(dispatch, "User added successfully", SnackbarType.success);

      dispatch(setCurrentAddUserInput(""));
      dispatch(setRefreshAccessGroupsUsers(true));
    }
  }, [dispatch, isSuccess]);

  return { addUser, isAddingUser };
}
