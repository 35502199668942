import { ListItemText } from "@mui/material";
import styled from "styled-components";

interface StyledListItemTextProps {
  $boldText: boolean;
}

export const StyledListItemText = styled(ListItemText)<StyledListItemTextProps>`
  &.MuiListItemText-root {
    display: flex;
    align-items: center;
    color: ${(props) =>
      props.$boldText
        ? `${props.theme.palettes.primary.dark}`
        : `${props.theme.palettes.grayscale.medium}`};
  }

  .MuiListItemText-primary {
    font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
    font-weight: ${(props) => (props.$boldText ? "bold" : "normal")};
    margin-left: 12px;
    margin-right: 12px;
  }

  .MuiListItemText-secondary {
    font-size: ${(props) => props.theme.typography.fontSizes.headerT2}px;
  }
`;
