import ClearIcon from "@mui/icons-material/Clear";
import { InputAdornment } from "@mui/material";
import { useRegexValidation } from "../../app/hooks/useRegexValidation";
import { FormHelperControl } from "../FormHelper/FormHelperControl";
import { InputDiv } from "../StyledComponents/InputDiv";
import { StyledIconButton } from "../StyledComponents/StyledIconButton";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { StyledTextField } from "./SC/StyledTextField";
import { useEffect, useState } from "react";

interface UncontrolledTextFieldInputProps {
  id: string;
  value: string;
  label: string;
  placeholder?: string;
  validationRegExp?: RegExp;
  validationMsg?: string;
  actionText: string;
  actionIcon: JSX.Element;
  allowClickOnError?: boolean;
  onClick: (hasError: boolean) => void;
  onChange: (text: string) => void;
  onClear: () => void;
  onError?: (isError: boolean) => void;
  isDisabled?: boolean;
  disabledMessage?: string;
}

export function UncontrolledTextFieldInput(
  props: UncontrolledTextFieldInputProps
) {
  const { isError, helperText } = useRegexValidation({
    validationMsg: props.validationMsg,
    validationRegExp: props.validationRegExp,
    inputValue: props.value,
  });
  const [searched, setSearched] = useState(false);
  const onError = props.onError;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
    setSearched(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && (!isError || props.allowClickOnError)) {
      props.onClick(isError);
      setSearched(true);
    }
  };

  const handleSearchClick = () => {
    if (!isError || props.allowClickOnError) {
      props.onClick(isError);
      setSearched(true);
    }
  };

  useEffect(() => {
    if (onError) {
      onError(isError);
    }
  }, [onError, isError]);

  return (
    <TooltipTop
      title={
        props.isDisabled && props.disabledMessage ? props.disabledMessage : ""
      }
    >
      <InputDiv>
        <StyledTextField
          disabled={props.isDisabled}
          $isEditable={!props.isDisabled}
          placeholder={props.placeholder}
          id={props.id}
          size="small"
          label={props.label}
          error={isError && (!props.allowClickOnError || searched)}
          variant="outlined"
          value={props.value}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {props.value && (
                  <TooltipTop title="Clear">
                    <StyledIconButton
                      $marginRight={-2}
                      $marginLeft={0}
                      padding={2}
                      onClick={props.onClear}
                    >
                      <ClearIcon />
                    </StyledIconButton>
                  </TooltipTop>
                )}
                <TooltipTop title={props.actionText}>
                  <StyledIconButton padding={2} onClick={handleSearchClick}>
                    {props.actionIcon}
                  </StyledIconButton>
                </TooltipTop>
              </InputAdornment>
            ),
          }}
        />
        <FormHelperControl
          isWarning={false}
          isError={isError && !props.allowClickOnError}
          texts={[helperText]}
          marginTop={0}
        />
      </InputDiv>
    </TooltipTop>
  );
}
