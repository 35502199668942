import { IconButton } from "@mui/material";
import { TooltipTop } from "../../Tooltips/ToolipTop";

interface IconButtonControlProps {
  id?: string;
  popupText: string;
  icon: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export function IconButtonControl(props: IconButtonControlProps) {
  return (
    <TooltipTop title={props.popupText}>
      <IconButton
        id={props.id}
        size="small"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.icon}
      </IconButton>
    </TooltipTop>
  );
}
