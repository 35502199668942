import { Skeleton } from "@mui/material";
import styled from "styled-components";

export const StyledSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    background-color: ${(props) => props.theme.palettes.grayscale.light};
    text-align: right;
    align-self: end;
  }
`;
