import { useAppDispatch } from "../../app/hooks/hooks";
import { ScreenSplashDiv } from "./SC/ScreenSplashDiv";
import { ScreenSplashImgDiv } from "./SC/ScreenSplashImgDiv";
import { ScreenSplashImgSpan } from "./SC/ScreenSplashImgSpan";
import { ScreenSplashMessageSpan } from "./SC/ScreenSplashMessageSpan";
import { ScreenSplashTitleSpan } from "./SC/ScreenSplashTitleSpan";
import { ScreenSplashImg } from "./SC/ScreenSplashImg";
import { StyledLink } from "./SC/StyledLink";
import { setUserAgreedForTooSmallWindow } from "../../app/slices/mainViewSlice";

interface ToSmallWindowScreenSplashProps {
  displaySplashScreen: boolean;
}

export function ToSmallWindowScreenSplash(
  props: ToSmallWindowScreenSplashProps
) {
  const dispatch = useAppDispatch();

  return (
    <ScreenSplashDiv $isVisible={props.displaySplashScreen}>
      <ScreenSplashImgDiv>
        <ScreenSplashImg src="/abbLogo.png" />
        <ScreenSplashImgSpan>
          Library Access Groups Management Interface
        </ScreenSplashImgSpan>
      </ScreenSplashImgDiv>
      <ScreenSplashTitleSpan>
        Your browser window is too small to display the application. Please make
        it bigger.
      </ScreenSplashTitleSpan>
      <ScreenSplashMessageSpan>
        You can still go to ABB Library Access Groups Management interface but
        the application might not be displayed in a proper way. Click
        <StyledLink
          to="#"
          onClick={() => {
            dispatch(setUserAgreedForTooSmallWindow(true));
          }}
        >
          here
        </StyledLink>
        if you still wish to proceed to the application.
      </ScreenSplashMessageSpan>
    </ScreenSplashDiv>
  );
}
