import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { SectionStep } from "./controls/SectionStep";
import { RemoveStep } from "./controls/RemoveStep";

export const peopleSteps = (isAnyRemoveButton: boolean): Step[] => {
  const steps: Step[] = [];

  steps.push(createStep("#access-group-list-div", <SectionStep />, 620, "bottom"));
  if (isAnyRemoveButton) {
    steps.push(createStep(".delete-button", <RemoveStep />, 720, "bottom"));
  }

  return steps;
};
