import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/members/remove_user.png";

export function RemoveUserGeneralStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                Administrator can remove a user from the group by clicking on the bin next to their email address.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot} />
            </Paragraph>
        </StepContentDiv>
    );
}
