import { UncontrolledTextFieldInput } from "./UncontrolledTextFieldInput";

interface TextFieldInputProps {
  id: string;
  initialValue: string;
  label: string;
  placeholder?: string;
  validationRegExp?: RegExp;
  validationMsg?: string;
  trimEntries?: boolean;
  onClick: (text: string, hasError: boolean) => void;
  onHandleChange?: (text: string) => void;
  onError?: (isError: boolean) => void;
  actionText: string;
  actionIcon: JSX.Element;
  allowClickOnError?: boolean;
  isDisabled?: boolean;
  disabledMessage?: string;
}

export function TextFieldInput(props: TextFieldInputProps) {
  const handleChange = (text: string) => {
    if (props.onHandleChange) {
      props.onHandleChange(text);
    }
  };

  const handleSearchClick = (hasError: boolean) => {
    const value =
      props.trimEntries === undefined || props.trimEntries
        ? props.initialValue.trim()
        : props.initialValue;
    props.onClick(value, hasError);
  };

  const handleClearPress = () => {
    props.onClick("", false);
  };

  return (
    <UncontrolledTextFieldInput
      {...props}
      onChange={handleChange}
      onClick={handleSearchClick}
      onClear={handleClearPress}
      value={props.initialValue}
    />
  );
}
