import { AccessGroupWithPermissions } from "../models/accessGroupWithPermissions";

export function orderGroupsByNameAndAccess(
  a: AccessGroupWithPermissions,
  b: AccessGroupWithPermissions
) {
  const lowerA = a.name.toLocaleLowerCase();
  const lowerB = b.name.toLocaleLowerCase();

  if (a.hasAdminAccess === b.hasAdminAccess) {
    return lowerA > lowerB ? 1 : lowerA === lowerB ? 0 : -1;
  }

  return a.hasAdminAccess ? -1 : 1;
}
