import styled from "styled-components";

export const FooterDiv = styled.div`
  display: flex;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  order: 2;
  width: 100%;
  justify-content: end;
  align-items: center;
  font-size: ${(props) => props.theme.typography.fontSizes.small}px;
  z-index: 0;
  background-color: ${(props) => props.theme.palettes.primary.light};
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
`;
