import { LogoDiv } from "./SC/LogoDiv";
import { LogoImg } from "./SC/LogoImg";
import { LogoSpan } from "./SC/LogoSpan";
import { StyledLink } from "./SC/StyledLink";

export function Logo() {
  return (
    <LogoDiv>
      <StyledLink to="/" reloadDocument>
        <LogoImg src="/abbLogo.png" width={76} height={29} alt="ABB logo" />
      </StyledLink>
      <LogoSpan>
        <StyledLink to={"/"} reloadDocument>
          Library Access Groups Management
        </StyledLink>
      </LogoSpan>
    </LogoDiv>
  );
}
