import { useCallback, useEffect } from "react";
import { useLazyGetAccessGroupCategoriesQuery } from "../../../../../app/apis/accessGroupsApi";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import useApiErrorHandling from "../../../../../app/hooks/useApiErrorHandling";
import { selectCurrentSearchAccessGroup } from "../../../../../app/slices/groupsSlice";
import { setIsAnyCategories } from "../../../../../app/slices/selfHelpSlice";

export function useGetCategories() {
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const dispatch = useAppDispatch();

  const [getAccessGroupCategories, { data: categories, isError: isCategoriesError, error, isFetching: isCategoriesFetching }] =
    useLazyGetAccessGroupCategoriesQuery();
  useApiErrorHandling({
    isError: isCategoriesError,
    error,
    customErrorMessage: "Error while fetching categories",
  });

  const fetchCategories = useCallback(() => {
    if (currentGroup?.name) {
      void getAccessGroupCategories(
        {
          version: 1,
          accessGroupName: currentGroup.name,
        },
        true
      );
    }
  }, [currentGroup?.name, getAccessGroupCategories]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      dispatch(setIsAnyCategories(true));
    } else {
      dispatch(setIsAnyCategories(false));
    }

  }, [categories, dispatch])

  return { fetchCategories, categories, isCategoriesError, isCategoriesFetching };
}
