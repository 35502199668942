import { BreadcrumbSeparator } from "./BreadcrumbSeparator";
import { BreadcrumbList } from "./SC/BreadcrumbList";
import { BreadcrumbListItem } from "./SC/BreadcrumbListItem";

interface BreadcrumbProps {
  isGray: boolean;
  categoryId: string;
  separator: string;
  categories: string[];
}

export function Breadcrumb(props: BreadcrumbProps) {
  return (
    <BreadcrumbList id={props.categoryId}>
      {props.categories.map((category, index) => {
        const lastCategoryIndex = props.categories.length - 1;
        return [
          <BreadcrumbListItem
            key={index}
            $isBolded={lastCategoryIndex == index}
            $isGray={props.isGray}
          >
            {category}
          </BreadcrumbListItem>,
          <BreadcrumbSeparator
            $isVisible={lastCategoryIndex != index}
            key={index + "separator"}
            separator={props.separator}
          />,
        ];
      })}
    </BreadcrumbList>
  );

}
