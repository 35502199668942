import { InformationControl } from "../../../../controls/InformationControl/InformationControl";

export function NoParentsFound() {
  return (
    <InformationControl
      id={"no-parents-found"}
      title="Nothing has been found"
      description="This group is not a member of any other access group."
      $showDash={false}
    />
  );
}
