export enum SelfHelp {
  None = "None",
  MenuBar = "MenuBar",
  People = "People",
  Members = "Members",
  Admins = "Admins",
  MemberOf = "MemberOf",
  Categories = "Categories",
}

export enum SelfHelpStatus {
  Completed = "grey",
  Mandatory = "red",
  Optional = "blue",
}

export interface SelfHelpSection {
  sectionName: SelfHelp;
  page: string;
  optional: boolean;
}

export enum SelfHelpPage {
  UserAccessManagement = "UserAccessManagement",
}

export interface SelfHelpSectionIdentifier {
  sectionName: SelfHelp;
  page: SelfHelpPage;
}