import { useCallback } from "react";
import { useAppDispatch } from "../../app/hooks/hooks";
import {
  setCurrentMembersSearch,
  setSearchedMemberEmail,
} from "../../app/slices/groupsSlice";
import { setTabType } from "../../app/slices/mainViewSlice";
import {
  setCurrentPerson,
  setPeopleSearchQuery,
  setSelectedPersonAccessGroup,
} from "../../app/slices/peopleSlice";
import { ItemAvatar } from "../../controls/HeaderItemAvatar/ItemAvatar";
import { MainViewTabType } from "../../models/MainViewTabType";
import { AccessGroupWithPermissions } from "../../models/accessGroupWithPermissions";
import { Person } from "../../models/person";
import { TabDiv } from "../../pages/layout/SC/TabDiv";
import { AdminsGroupColumn } from "./GroupColumn/AdminsGroupColumn";
import MembersGroupColumn from "./GroupColumn/MembersGroupColumn";
import { GroupsContentDiv } from "./SC/GroupsContentDiv";
import MemberOfGroupColumn from "./GroupColumn/MemberOfGroupColumn";
import CategoriesGroupColumn from "./GroupColumn/CategoriesGroupColumn";
import { ColumnDiv } from "./SC/ColumnDiv";

interface GroupsControlProps {
  accessGroup: AccessGroupWithPermissions;
}

export function GroupsControl(props: GroupsControlProps) {
  const dispatch = useAppDispatch();

  const onAccessGroupClick = useCallback(
    (group: AccessGroupWithPermissions) => {
      dispatch(
        setSelectedPersonAccessGroup({
          name: group.name,
          currentUserHasAdminAccess: group.hasAdminAccess,
        })
      );
      dispatch(setCurrentMembersSearch(""));
      dispatch(setSearchedMemberEmail(""));
    },
    [dispatch]
  );

  const onPersonClick = useCallback(
    (person: Person) => {
      dispatch(setTabType(MainViewTabType.People));
      dispatch(setCurrentPerson(person));
      dispatch(setPeopleSearchQuery(person.email ?? ""));
    },
    [dispatch]
  );

  return (
    <TabDiv id="groups-tab-div">
      <ItemAvatar
        width="90%"
        primaryInfo={props.accessGroup.name}
        secondaryInfo={"ID: " + props.accessGroup.id}
        hasAdminAccessToGroup={props.accessGroup.hasAdminAccess}
      />
      <GroupsContentDiv>
        <MembersGroupColumn
          onAccessGroupClick={onAccessGroupClick}
          onPersonClick={onPersonClick}
        />
        <AdminsGroupColumn onPersonClick={onPersonClick} />
        <ColumnDiv>
          <MemberOfGroupColumn onAccessGroupClick={onAccessGroupClick} />
          <CategoriesGroupColumn />
        </ColumnDiv>

      </GroupsContentDiv>
    </TabDiv>
  );
}
