import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import { UserDataSpan } from "./SC/UserDataSpan";

interface UserDataRowProps {
  data: string;
  $isBolded: boolean;
}

export function UserDataRow(props: UserDataRowProps) {
  return (
    <TooltipTop title={props.data}>
      <UserDataSpan $isBolded={props.$isBolded}>{props.data}</UserDataSpan>
    </TooltipTop>
  );
}
