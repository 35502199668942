import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function MemberOfSectionStep2() {
    return (
        <StepContentDiv>
            <Paragraph>
                Clicking on a group name will open the Groups section for that group.
            </Paragraph>
        </StepContentDiv>
    );
}
