import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  FilterOptionsState,
  InputAdornment,
  PopperProps,
} from "@mui/material";
import {
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTheme } from "styled-components";
import { AccessGroup } from "../../models/accessGroup";
import { AccessGroupWithPermissions } from "../../models/accessGroupWithPermissions";
import { SearchInputDiv } from "../SearchInput/SC/SearchInputDiv";
import { StyledTextField } from "../TextField/SC/StyledTextField";
import { TooltipTop } from "../Tooltips/ToolipTop";
import AutocompleteErrorMessage from "./AutocompleteErrorMessage";
import { StyledAutoCompletePopper } from "./SC/StyledAutoCompletePopper";

interface AutocompleteControlProps {
  id: string;
  options: AccessGroupWithPermissions[];
  initialValue: AccessGroupWithPermissions | null;
  selectedGroupName?: string;
  initialInputValue: string;
  isFetchingError: boolean;
  isLoading: boolean;
  onChangeHandler: (value: AccessGroupWithPermissions | null) => void;
  onInputChange?: (value: string) => void;
  onRefreshHandler: () => void;
  onOptionsFilter: (
    options: AccessGroupWithPermissions[],
    state: FilterOptionsState<AccessGroupWithPermissions>,
    inputValue: string
  ) => AccessGroupWithPermissions[];
  width: number;
}

const getPopperWidth = (parentInputWidth?: number) => {
  return (parentInputWidth ?? 200) - 2; // subtracting few pixels to match parent input width with border
};

export function AutocompleteControl(props: AutocompleteControlProps) {
  const theme = useTheme();
  const [selected, setSelected] = useState<AccessGroupWithPermissions | null>(
    props.initialValue
  );
  const [inputValue, setInputValue] = useState(props.initialInputValue);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.initialInputValue) {
      setInputValue(props.initialInputValue);
    }
  }, [props.initialInputValue]);

  const onChangeHandler = useCallback(
    (event: SyntheticEvent, value: AccessGroupWithPermissions | null) => {
      setSelected(value);
      props.onChangeHandler(value);
      event.stopPropagation();
    },
    [props]
  );

  const selectedValue = useMemo(() => {
    if (props.selectedGroupName) {
      return (
        props.options.find((x) => x.name === props.selectedGroupName) ?? null
      );
    }

    return props.options.find((x) => x.id === selected?.id) ?? null;
  }, [props.selectedGroupName, selected?.id, props.options]);

  const customPopper = useCallback(
    (popperProps: PopperProps) => {
      if (props.isFetchingError) {
        return (
          <AutocompleteErrorMessage
            width={inputRef.current?.clientWidth ?? 420}
            topMargin={inputRef.current?.clientHeight ?? 34}
            refreshHandler={props.onRefreshHandler}
          />
        );
      }

      return (
        <StyledAutoCompletePopper
          {...popperProps}
          anchorEl={inputRef.current}
          $calculatedWidth={getPopperWidth(inputRef.current?.clientWidth)}
        />
      );
    },
    [props.isFetchingError, props.onRefreshHandler]
  );

  const getMenuItem = (
    props: HTMLAttributes<HTMLLIElement>,
    option: unknown
  ) => {
    const item = option as AccessGroupWithPermissions;
    return (
      <Box
        component="li"
        {...props}
        fontWeight={item.hasAdminAccess ? "bold" : "normal"}
        color={
          item.hasAdminAccess
            ? theme.palettes.primary.dark
            : theme.palettes.grayscale.medium
        }
      >
        {item.name}
      </Box>
    );
  };

  const getInputEndAdornment = (params: AutocompleteRenderInputParams) => {
    return (
      <>
        {props.isLoading && <CircularProgress color="inherit" size={20} />}
        {params.InputProps.endAdornment}
      </>
    );
  };

  return (
    <SearchInputDiv id={props.id + "-input-div"} $width={props.width}>
      <Autocomplete
        ref={inputRef}
        disablePortal
        id={props.id}
        multiple={false}
        options={props.options}
        renderOption={getMenuItem}
        value={selectedValue}
        clearOnBlur
        onOpen={() => {
          setSelected(null);
          setInputValue("");
        }}
        onChange={onChangeHandler}
        getOptionLabel={(option) => option.name}
        PopperComponent={customPopper}
        inputValue={inputValue}
        loading={props.isLoading}
        blurOnSelect={true}
        isOptionEqualToValue={(
          option: AccessGroup,
          value: AccessGroup | null
        ) => option.id === value?.id}
        onInputChange={(
          event: SyntheticEvent<Element, Event> | undefined,
          value,
          reason
        ) => {
          if (reason !== "reset" || event?.type === "click") {
            setInputValue(value);

            if (props.onInputChange) {
              props.onInputChange(value);
            }
          }
        }}
        filterOptions={(options, state) =>
          props.onOptionsFilter(options, state, inputValue)
        }
        renderInput={(params) => (
          <StyledTextField
            {...params}
            $isEditable={true}
            size="small"
            variant="outlined"
            placeholder="Type search query (min. 3 chars)"
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <TooltipTop title="Search">
                    <SearchIcon />
                  </TooltipTop>
                </InputAdornment>
              ),
              endAdornment: getInputEndAdornment(params),
              ref: params.InputProps.ref,
            }}
          />
        )}
      />
    </SearchInputDiv>
  );
}
