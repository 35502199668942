import styled from "styled-components";

interface ScreenSplashDivProps {
  $isVisible: boolean;
}

export const ScreenSplashDiv = styled.div<ScreenSplashDivProps>`
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  visibility: ${(props) => (props.$isVisible ? "visible" : "hidden")};
  max-height: ${(props) => (props.$isVisible ? "100%" : "0")};
`;
