import { Popper } from "@mui/material";
import styled from "styled-components";

interface StyledAutoCompletePopperProps {
  $calculatedWidth: number;
}

export const StyledAutoCompletePopper = styled(
  Popper
)<StyledAutoCompletePopperProps>`
  width: ${(props) => props.$calculatedWidth}px !important;
  border: 1px solid ${(props) => props.theme.palettes.grayscale.medium};
  border-radius: 4px;
  word-break: break-word;

  & .MuiAutocomplete-listbox {
    padding: 4px 0;
    background-color: ${(props) =>
      props.theme.palettes.grayscale.white} !important;
  }

  & .MuiAutocomplete-paper {
    background-color: ${(props) =>
      props.theme.palettes.grayscale.white} !important;
  }

  & .MuiAutocomplete-noOptions {
    padding: 10px 16px;
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.medium}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    background-color: ${(props) => props.theme.palettes.grayscale.white};
  }
`;
