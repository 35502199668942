import { useEffect, useState } from "react";

interface RegexValidationProps {
  validationRegExp?: RegExp;
  validationMsg?: string;
  inputValue?: string;
}

export function useRegexValidation(props: RegexValidationProps) {
  const [isError, setIsError] = useState(false);
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    if (props.validationRegExp && props.validationMsg) {
      let hasError = true;
      let helperText = props.validationMsg;

      if (!props.inputValue || props.validationRegExp.test(props.inputValue)) {
        hasError = false;
        helperText = "";
      }

      setIsError(hasError);
      setHelperText(helperText);
    }
  }, [props.inputValue, props.validationMsg, props.validationRegExp]);

  return { isError, helperText };
}
