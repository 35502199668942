import { useCallback, useEffect, useState } from "react";
import { useLazyGetUserAccessGroupsQuery } from "../../../app/apis/accessGroupsUsersApi";
import { useAppDispatch } from "../../../app/hooks/hooks";
import useApiErrorHandling from "../../../app/hooks/useApiErrorHandling";
import { setPersonWasNotFound } from "../../../app/slices/peopleSlice";

interface GetPersonAccessGroupsProps {
  email: string | null;
  onAccessGroupsFetch: () => void;
}

export function useGetPersonAccessGroups(props: GetPersonAccessGroupsProps) {
  const dispatch = useAppDispatch();
  const [getUserAccessGroups, { data, isError, error, isFetching }] =
    useLazyGetUserAccessGroupsQuery();
  const onAccessGroupsFetch = props.onAccessGroupsFetch;

  const fetchAccessGroups = useCallback(() => {
    if (props.email) {
      void getUserAccessGroups({ version: 1, userEmail: props.email }, true);
      onAccessGroupsFetch();
    }
  }, [getUserAccessGroups, props.email, onAccessGroupsFetch]);
  const [isPeopleError, setIsPeopleError] = useState(false);
  useApiErrorHandling({
    isError: isPeopleError,
    error: error,
    customErrorMessage: "Error while fetching user access groups",
  });

  useEffect(() => {
    fetchAccessGroups();
  }, [fetchAccessGroups]);

  useEffect(() => {
    const status =
      error !== undefined ? (error as { status?: number }).status : undefined;
    const userNotFound = isError && status !== undefined && status === 404;
    const isPeopleError = isError && !userNotFound;
    dispatch(setPersonWasNotFound(userNotFound));
    setIsPeopleError(isPeopleError);
  }, [isError, error, dispatch]);

  return {
    personAccessGroups: data,
    isError: isPeopleError,
    isLoading: isFetching,
    fetchAccessGroups,
  };
}
