import { useAppSelector } from "../../app/hooks/hooks";
import {
  selectCurrentPersonAccessGroups,
  selectHasCurrentPersonValidationError,
  selectPersonWasNotFound,
} from "../../app/slices/peopleSlice";
import { HeaderItemAvatar } from "../../controls/HeaderItemAvatar/HeaderItemAvater";
import { InformationControl } from "../../controls/InformationControl/InformationControl";
import { Person } from "../../models/person";
import { TabDiv } from "../../pages/layout/SC/TabDiv";
import { AccessGroupList } from "./accessGroups/AccessGroupsList";

interface PersonProps {
  person: Person;
}

export function PersonControl(props: PersonProps) {
  const personNotFound = useAppSelector(selectPersonWasNotFound);
  const accessGroups = useAppSelector(selectCurrentPersonAccessGroups);
  const hasValidationError = useAppSelector(
    selectHasCurrentPersonValidationError
  );

  if (hasValidationError) {
    return (
      <TabDiv id="people-tab-div">
        <InformationControl
          id={"no-access-groups"}
          title="Invalid email address"
          description="The provided email address is invalid."
          $showDash={false}
        />
      </TabDiv>
    );
  }

  if (personNotFound) {
    return (
      <TabDiv id="people-tab-div">
        <InformationControl
          id={"user-not-found"}
          title="User not found"
          description="User with the provided email address does not exist."
          $showDash={false}
        />
      </TabDiv>
    );
  }

  if (accessGroups?.length === 0) {
    return (
      <TabDiv id="people-tab-div">
        <HeaderItemAvatar
          width="60%"
          primaryInfo={props.person.name}
          secondaryInfo={props.person.email}
        />
        <InformationControl
          id={"no-access-groups"}
          title="No access groups"
          description="This user has no access groups assigned."
          $showDash={false}
        />
      </TabDiv>
    );
  }

  return (
    <TabDiv id="people-tab-div">
      <HeaderItemAvatar
        width="60%"
        primaryInfo={props.person.name}
        secondaryInfo={props.person.email}
      />
      <AccessGroupList person={props.person} />
    </TabDiv>
  );
}
