import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function NoContentNoAccessRemoveStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                This field is used to add a new administrator to the group using their full email address.
            </Paragraph>
        </StepContentDiv>
    );
}
