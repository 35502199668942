import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { GroupSelectorStep } from "./controls/GroupSelectorStep";
import { GroupsTabStep } from "./controls/GroupsTabStep";
import { PeopleSelectorStep } from "./controls/PeopleSelectorStep";
import { PeopleTabStep } from "./controls/PeopleTabStep";

export const menuBarSteps = (): Step[] => {
  return [
    createStep(
      "#groups-tab",
      <GroupsTabStep />,
      520,
      "bottom"
    ),
    createStep(
      "#people-tab",
      <PeopleTabStep />,
      520,
      "bottom"
    ),
    createStep("#menu-bar-selector-div", <GroupSelectorStep />, 720, "bottom"),
    createStep("#menu-bar-selector-div", <PeopleSelectorStep />, 720, "bottom"),
  ]

};
