import { StyledListItemText } from "./SC/StyledListItemText";
import { ItemAvatarDiv } from "./SC/ItemAvatarDiv";
import { StyledListItem } from "./SC/StyledListItem";
import { useAppSelector } from "../../app/hooks/hooks";
import { selectIsSuperAdmin } from "../../app/slices/authenticationSlice";

export interface UserInfoProps {
  primaryInfo: string | null;
  secondaryInfo: string | null;
  width: string;
  hasAdminAccessToGroup: boolean;
}

export function ItemAvatar(props: UserInfoProps) {
  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);

  return (
    <ItemAvatarDiv width={props.width}>
      <StyledListItem>
        <StyledListItemText
          primary={props.primaryInfo}
          secondary={
            isSuperAdmin && props.secondaryInfo
              ? `(${props.secondaryInfo})`
              : null
          }
          $boldText={props.hasAdminAccessToGroup}
        />
      </StyledListItem>
    </ItemAvatarDiv>
  );
}
