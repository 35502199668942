import CloseIcon from "@mui/icons-material/Close";
import {
  Breakpoint,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { IconButtonControl } from "../Buttons/SC/IconButtonControl";
import { StyledDisabledTooltipMsg } from "../Tooltips/SC/StyledDisabledTooltipMsg";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { DialogButton } from "./SC/DialogButton";
import { StyledDialog } from "./SC/StyledDialog";
import { StyledDialogTitle } from "./SC/StyledDialogTitle";
import { StyledDialogDiv } from "./SC/StyledDialogDiv";

interface DialogControlProps {
  id: string;
  isOpen: boolean;
  useActions: boolean;
  title: string;
  content: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxWidth: Breakpoint | false;
  onCloseClick: (e: React.MouseEvent) => void;
  onConfirmClick?: (e: React.MouseEvent) => void;
  onCancelClick?: (e: React.MouseEvent) => void;
  onExtraButtonClick?: (e: React.MouseEvent) => void;
  confirmText?: string;
  cancelText?: string;
  extraButtonText?: string;
  padding?: string;
  showExtraButton?: boolean;
  disabledConfirm?: boolean;
  disabledConfirmTooltipMsg?: string;
}

export function DialogControl(props: DialogControlProps) {
  const confirmText = props.confirmText ? props.confirmText : "Confirm";

  return (
    <StyledDialog
      id={props.id + "-dialog"}
      fullWidth={props.fullWidth}
      $fullHeight={props.fullHeight}
      maxWidth={props.maxWidth}
      open={props.isOpen}
      onClose={props.onCloseClick}
      $padding={props.padding}
    >
      <StyledDialogTitle id={props.id + "-dialog-title"}>
        {props.title}
        <StyledDialogDiv>
          <IconButtonControl
            id={props.id + "-dialog-close-button"}
            popupText="Close"
            onClick={props.onCloseClick}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </StyledDialogDiv>
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      {props.useActions && (
        <DialogActions>
          <DialogButton
            id={props.id + "-dialog-confirm-button"}
            $color="primary"
            onClick={props.onConfirmClick}
            autoFocus
            disabled={props.disabledConfirm ?? false}
          >
            {props.disabledConfirm ? (
              <TooltipTop title={props.disabledConfirmTooltipMsg ?? ""}>
                <StyledDisabledTooltipMsg>
                  {confirmText}
                </StyledDisabledTooltipMsg>
              </TooltipTop>
            ) : (
              <>{confirmText}</>
            )}
          </DialogButton>
          {props.showExtraButton && props.extraButtonText && (
            <DialogButton
              id={props.id + "-dialog-extra-button"}
              $color="secondary"
              onClick={props.onExtraButtonClick}
            >
              {props.extraButtonText}
            </DialogButton>
          )}
          <DialogButton
            id={props.id + "-dialog-cancel-button"}
            $color="secondary"
            onClick={
              props.onCancelClick ? props.onCancelClick : props.onCloseClick
            }
          >
            {props.cancelText ? props.cancelText : "Cancel"}
          </DialogButton>
        </DialogActions>
      )}
    </StyledDialog>
  );
}
