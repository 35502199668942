import styled from "styled-components";

interface AddInputDivProps {
  $marginTop?: number;
}

export const AddInputDiv = styled.div<AddInputDivProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.$marginTop ?? 12) + "px"};
  margin-right: 12px;
  margin-left: 12px;
`;
