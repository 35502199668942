import { CircularProgress } from "@mui/material";
import { sortBy } from "lodash";
import { useEffect, useMemo } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { selectIsSuperAdmin } from "../../../app/slices/authenticationSlice";
import {
  selectAdminAccessGroups,
  selectCurrentSearchAccessGroup,
  selectRefreshAccessGroupsUsers,
  setRefreshAccessGroupsUsers,
} from "../../../app/slices/groupsSlice";
import { ErrorDisplay } from "../../../controls/ErrorHandling/ErrorDisplay";
import { SuperDenseItemAvatar } from "../../../controls/ItemAvatar/SuperDenseItemAvatar";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { PaperContentCenterDiv } from "../../../controls/Paper/SC/PaperContentCenterDiv";
import { PaperContentDiv } from "../../../controls/Paper/SC/PaperContentDiv";
import { orderGroupsByNameAndAccess } from "../../../helpers/accessGroupsHelper";
import { AccessGroupWithPermissions } from "../../../models/accessGroupWithPermissions";
import { Person } from "../../../models/person";
import { AddUserControl } from "./Add/AddUserControl";
import { NoAccessToMembers } from "./NotFound/NoAccessToMembers";
import { NoUsersFound } from "./NotFound/NoUsersFound";
import { GroupsColumnDiv } from "./SC/GroupsColumnDiv";
import { InputDiv } from "./SC/InputDiv";
import { LinearProgressDiv } from "./SC/LinearProgressDiv";
import { MembersSearchControl } from "./Search/MembersSearchControl";
import { useGetGroups } from "./hooks/Groups/useGetGroups";
import { useAddUser } from "./hooks/Users/useAddUser";
import { useGetUsers } from "./hooks/Users/useGetUsers";
import { useRemoveUser } from "./hooks/Users/useRemoveUser";
import { StyledLinearProgress } from "./SC/StyledLinearProgress";
import { ListChildComponentProps } from "react-window";
import { StyledFixedSizeList } from "./Search/SC/StyledFixedSizeList";
import { SelfHelp } from "../../../models/selfHelpType";

type ListChildComponent = {
  id: string;
  key: string;
  primaryInfo: string;
  type: "person" | "group";
  isBold: boolean;
  isBlackColor: boolean;
  canAdministrate?: boolean;
  onClick: () => void;
  onDelete?: () => void;
};

interface MembersGroupColumnProps {
  onAccessGroupClick: (group: AccessGroupWithPermissions) => void;
  onPersonClick: (person: Person) => void;
}

function MembersGroupColumn(props: MembersGroupColumnProps) {
  const theme = useTheme();
  const adminGroups = useAppSelector(selectAdminAccessGroups);
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);
  const refreshAccessGroupUsers = useAppSelector(
    selectRefreshAccessGroupsUsers
  );
  const dispatch = useAppDispatch();
  const isAdministrator = isSuperAdmin || currentGroup?.hasAdminAccess;

  const { fetchChildren, isChildrenError, isChildrenFetching, children } =
    useGetGroups();
  const { fetchUsers, isUsersError, isUsersFetching, users } = useGetUsers();
  const { addUser, isAddingUser } = useAddUser();
  const { removeUser, isRemovingUser } = useRemoveUser();

  const childrenWithAccess = useMemo(() => {
    const childrenWithPermissions = children?.map((x) => ({
      id: x.id,
      name: x.name,
      hasAdminAccess: !!adminGroups.find((y) => y.name === x.name),
    }));

    return childrenWithPermissions?.sort(orderGroupsByNameAndAccess);
  }, [children, adminGroups]);

  const rows = useMemo(() => {
    let rows: ListChildComponent[] = [];

    if (childrenWithAccess) {
      rows = rows.concat(
        childrenWithAccess.map((ag) => {
          return {
            id: "access-group-li-item-" + ag.name,
            key: ag.id.toString(),
            primaryInfo: ag.name,
            type: "group",
            isBold: ag.hasAdminAccess,
            isBlackColor: ag.hasAdminAccess,
            onClick: () => props.onAccessGroupClick(ag),
          };
        })
      );
    }

    rows = rows.concat(
      sortBy(users, ["email"]).map((p) => {
        return {
          id:
            p.email !== null
              ? "member-li-item-" + p.email
              : "member-person-li-item",
          key: p.email ?? "",
          primaryInfo: p.email !== null ? p.email : "",
          type: "person",
          isBold: false,
          isBlackColor: true,
          canAdministrate: isAdministrator,
          onClick: () => props.onPersonClick(p),
          onDelete: () => removeUser(p.email, currentGroup?.name),
        };
      })
    );

    return rows;
  }, [
    childrenWithAccess,
    users,
    removeUser,
    currentGroup?.name,
    props,
    isAdministrator,
  ]);

  useEffect(() => {
    fetchChildren();
  }, [fetchChildren]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (refreshAccessGroupUsers) {
      fetchUsers();
      dispatch(setRefreshAccessGroupsUsers(false));
    }
  }, [dispatch, fetchUsers, refreshAccessGroupUsers]);

  if (!isAdministrator) {
    return (
      <GroupsColumnDiv id="group-members-div" width={30} height={100}>
        <PaperControl title={"Members"} selfHelp={SelfHelp.Members}>
          <PaperContentCenterDiv id="no-access-group-members-div" >
            <NoAccessToMembers />
          </PaperContentCenterDiv>
        </PaperControl>
      </GroupsColumnDiv>
    );
  }

  if (isChildrenError || isUsersError) {
    return (
      <GroupsColumnDiv id="group-members-div" width={30} height={100}>
        <PaperControl title={"Members"} selfHelp={SelfHelp.Members}>
          <PaperContentCenterDiv>
            <ErrorDisplay
              errorId={"members"}
              errorMessageTitle={"Something went wrong"}
              errorDescription={
                isChildrenError
                  ? "Error while loading children access groups."
                  : "Error while loading access group users."
              }
              refreshFunction={isChildrenError ? fetchChildren : fetchUsers}
              $showDash={false}
              showReloadButton={true}
              centerHeader={true}
            />
          </PaperContentCenterDiv>
        </PaperControl>
      </GroupsColumnDiv>
    );
  }

  function renderRow(listChildProps: ListChildComponentProps) {
    const { index, style, data } = listChildProps;
    const row = data[index] as ListChildComponent;

    return (
      <SuperDenseItemAvatar
        origin="members"
        style={style}
        id={row.id}
        key={row.key}
        primaryInfo={row.primaryInfo}
        type={row.type}
        isBold={row.isBold}
        isBlackColor={row.isBlackColor}
        canAdministrate={row.canAdministrate}
        onClick={row.onClick}
        onDelete={row.onDelete}
      />
    );
  }

  const content = () => {
    if (isChildrenFetching || isUsersFetching) {
      return (
        <PaperContentCenterDiv>
          <CircularProgress
            sx={{ color: theme.palettes.primary.main }}
            size="30px"
          />
        </PaperContentCenterDiv>
      );
    }

    if (
      (users && users.length > 0) ||
      (childrenWithAccess && childrenWithAccess.length > 0)
    ) {
      return (
        <PaperContentDiv id="members-content-div">
          <StyledFixedSizeList
            key={"list"}
            height={1200}
            width={"100%"}
            itemSize={25}
            overscanCount={5}
            itemCount={rows.length}
            itemData={rows}
          >
            {renderRow}
          </StyledFixedSizeList>
        </PaperContentDiv>
      );
    }

    return (
      <PaperContentCenterDiv id="members-not-found-content-div">
        <NoUsersFound />
      </PaperContentCenterDiv>
    );
  };

  return (
    <GroupsColumnDiv id="group-members-div" width={30} height={100}>
      <PaperControl title={"Members"} selfHelp={SelfHelp.Members}>
        <InputDiv>
          <LinearProgressDiv>
            {(isAddingUser || isRemovingUser) && (
              <StyledLinearProgress />
            )}
          </LinearProgressDiv>
          <MembersSearchControl isDisabled={isUsersFetching} />
          <AddUserControl isDisabled={isUsersFetching} onAddUser={addUser} />
        </InputDiv>
        {content()}
      </PaperControl>
    </GroupsColumnDiv>
  );
}

export default MembersGroupColumn;
