import { useCallback, useEffect } from "react";
import { useMarkSectionAsCompletedMutation } from "../../../app/apis/selfHelpApi";
import { useAppDispatch } from "../../../app/hooks/hooks";
import { SelfHelpSectionIdentifier } from "../../../models/selfHelpType";
import { openSnackbar } from "../../../app/helpers/snackbarHelpers";
import { SnackbarType } from "../../../models/snackbar";
import { useGetSelfHelp } from "./useGetSelfHelp";

export function useMarkSectionCompleted() {
    const dispatch = useAppDispatch();
    const { fetchUncompletedSelfHelps } = useGetSelfHelp()

    const [
        markSection,
        {
            isSuccess: markAsCompletedSuccess,
            isError: markAsCompletedError,
        },
    ] = useMarkSectionAsCompletedMutation();

    const markSectionAsCompleted = useCallback(
        (arg: SelfHelpSectionIdentifier) => {
            void markSection({
                version: 1,
                page: arg.page,
                sectionName: arg.sectionName,
            });
        },
        [markSection]
    );

    useEffect(() => {
        if (markAsCompletedError) {
            openSnackbar(
                dispatch,
                "Error while completing section",
                SnackbarType.error
            );
        }
    }, [dispatch, markAsCompletedError]);

    useEffect(() => {
        if (markAsCompletedSuccess) {
            fetchUncompletedSelfHelps();
            openSnackbar(
                dispatch,
                "Section marked as completed",
                SnackbarType.success
            );
        }
    }, [dispatch, fetchUncompletedSelfHelps, markAsCompletedSuccess]);

    return { markSectionAsCompleted };
}
