import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ConfigType } from "../config/config";

export const configApi = createApi({
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getAppSettings: builder.query<ConfigType, null>({
      query: () => ({
        url: `/appSettings.json`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
      }),
    }),
  }),
});

export const { useGetAppSettingsQuery } = configApi;
export default configApi.reducer;
