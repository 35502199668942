
import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ContentStep() {
    const theme = useTheme();
    return (
        <StepContentDiv>
            <Paragraph>
                Groups that can be managed by current user are in{" "}
                <span style={{ fontWeight: "bold" }}>
                    bold, black font
                </span>. Groups to which current user does not have administrator rights are{" "}
                <span style={{ color: theme.palettes.grayscale.medium }}>
                    greyed-out
                </span>.
            </Paragraph>
        </StepContentDiv>
    );
}
