import styled from "styled-components";

export const ErrorContainerDiv = styled.div<{
  $padding?: string;
  $position?: string;
  $top?: string;
  $left?: string;
  $transform?: string;
}>`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${({ $padding = "0 0 80px 0" }) => $padding};
  align-items: center;
  justify-content: center;
  text-align: center;
  position: ${({ $position = "static" }) => $position};
  top: ${({ $top = "initial" }) => $top};
  left: ${({ $left = "initial" }) => $left};
  transform: ${({ $transform = "none" }) => $transform};
`;
