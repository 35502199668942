import DeleteIcon from "@mui/icons-material/Delete";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ContentNoAccessRemoveStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                Removing a user from Admins list is disabled for non-administrators (greyed-out <DeleteIcon sx={{ verticalAlign: "bottom", color: "rgba(0, 0, 0, 0.26)" }} /> icon).
            </Paragraph>
        </StepContentDiv>
    );
}
