import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SelfHelp, SelfHelpSection } from "../../models/selfHelpType";


export interface SelfHelpState {
  current: SelfHelp;
  isAnyMembers: boolean;
  isAnyCategories: boolean;
  isAnyAdmins: boolean;
  isAnyMemberOf: boolean;
  uncompletedSections: SelfHelpSection[];
}

const initialState: SelfHelpState = {
  current: SelfHelp.None,
  isAnyMembers: false,
  isAnyCategories: false,
  isAnyAdmins: false,
  isAnyMemberOf: false,
  uncompletedSections: [],
};

export const selfHelpSlice = createSlice({
  name: "selfHelp",
  initialState,
  reducers: {
    setCurrentSelfHelp(
      state: SelfHelpState,
      action: PayloadAction<SelfHelp>
    ) {
      state.current = action.payload;
    },
    setIsAnyAdmins(
      state: SelfHelpState,
      action: PayloadAction<boolean>
    ) {
      state.isAnyAdmins = action.payload;
    },
    setIsAnyCategories(
      state: SelfHelpState,
      action: PayloadAction<boolean>
    ) {
      state.isAnyCategories = action.payload;
    },
    setIsAnyMembers(
      state: SelfHelpState,
      action: PayloadAction<boolean>
    ) {
      state.isAnyMembers = action.payload;
    },
    setIsAnyMemberOf(
      state: SelfHelpState,
      action: PayloadAction<boolean>
    ) {
      state.isAnyMemberOf = action.payload;
    },
    setUncompletedSections(
      state: SelfHelpState,
      action: PayloadAction<SelfHelpSection[]>
    ) {
      state.uncompletedSections = action.payload;
    },
  },
});

export const { setCurrentSelfHelp, setIsAnyAdmins, setIsAnyCategories, setIsAnyMembers, setIsAnyMemberOf, setUncompletedSections } = selfHelpSlice.actions;

export const selectCurrentSelfHelp = (state: RootState) =>
  state.selfHelp.current;
export const selectIsAnyAdmins = (state: RootState) =>
  state.selfHelp.isAnyAdmins;
export const selectIsAnyCategories = (state: RootState) =>
  state.selfHelp.isAnyCategories;
export const selectIsAnyMembers = (state: RootState) =>
  state.selfHelp.isAnyMembers;
export const selectIsAnyMemberOf = (state: RootState) =>
  state.selfHelp.isAnyMemberOf;
export const selectUncompletedSections = (state: RootState) =>
  state.selfHelp.uncompletedSections;

export default selfHelpSlice.reducer;
