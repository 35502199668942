import { CircularProgress } from "@mui/material";
import { sortBy } from "lodash";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { selectIsSuperAdmin } from "../../../app/slices/authenticationSlice";
import {
  selectCurrentSearchAccessGroup,
  selectRefreshAccessGroupsAdmins,
  setRefreshAccessGroupsAdmins,
} from "../../../app/slices/groupsSlice";
import { ErrorDisplay } from "../../../controls/ErrorHandling/ErrorDisplay";
import { DenseItemAvatar } from "../../../controls/ItemAvatar/DenseItemAvatar";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { PaperContentCenterDiv } from "../../../controls/Paper/SC/PaperContentCenterDiv";
import { PaperContentDiv } from "../../../controls/Paper/SC/PaperContentDiv";
import { Person } from "../../../models/person";
import { AddAdminControl } from "./Add/AddAdminControl";
import { NoAdminsFound } from "./NotFound/NoAdminsFound";
import { GroupsColumnDiv } from "./SC/GroupsColumnDiv";
import { InputDiv } from "./SC/InputDiv";
import { LinearProgressDiv } from "./SC/LinearProgressDiv";
import { AdminsSearchControl } from "./Search/AdminsSearchControl";
import { GroupsList } from "./Search/SC/GroupsList";
import { useAddAdmin } from "./hooks/Admins/useAddAdmin";
import { useGetAdmins } from "./hooks/Admins/useGetAdmins";
import { useRemoveAdmin } from "./hooks/Admins/useRemoveAdmin";
import { StyledLinearProgress } from "./SC/StyledLinearProgress";
import { SelfHelp } from "../../../models/selfHelpType";

interface AdminsGroupColumnProps {
  searchControl?: React.ReactNode;
  hasSearchValidationError?: boolean;
  onPersonClick: (person: Person) => void;
}

export const noAccessMessage =
  "You are not an administrator of this access group";

export function AdminsGroupColumn(props: AdminsGroupColumnProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);
  const refreshAccessGroupAdmins = useAppSelector(
    selectRefreshAccessGroupsAdmins
  );
  const isAdministrator = isSuperAdmin || currentGroup?.hasAdminAccess;

  const { fetchAdmins, isAdminsFetching, isAdminsError, users } =
    useGetAdmins();
  const { removeAdmin, isRemovingAdmin } = useRemoveAdmin();
  const { addAdmin, isAddingAdmin } = useAddAdmin();


  useEffect(() => {
    fetchAdmins();
  }, [fetchAdmins]);

  useEffect(() => {
    if (refreshAccessGroupAdmins) {
      fetchAdmins();
      dispatch(setRefreshAccessGroupsAdmins(false));
    }
  }, [dispatch, fetchAdmins, refreshAccessGroupAdmins]);

  if (isAdminsError) {
    return (
      <GroupsColumnDiv id="admins-column" width={30} height={100}>
        <PaperControl title={"Admins"} selfHelp={SelfHelp.Admins}>
          <PaperContentCenterDiv>
            <ErrorDisplay
              errorId={"members"}
              errorMessageTitle={"Something went wrong"}
              errorDescription={"Error while fetching access group admins."}
              refreshFunction={fetchAdmins}
              $showDash={false}
              showReloadButton={true}
              centerHeader={true}
            />
          </PaperContentCenterDiv>
        </PaperControl>
      </GroupsColumnDiv>
    );
  }

  const content = () => {
    if (isAdminsFetching) {
      return (
        <PaperContentCenterDiv>
          <CircularProgress
            sx={{ color: theme.palettes.primary.main }}
            size="30px"
          />
        </PaperContentCenterDiv>
      );
    }

    if (users && users.length > 0) {
      return (
        <PaperContentDiv id="admins-content-div">
          <GroupsList id={"list"} sx={{
            // hover states
            '& .MuiListItem-root:hover': {
              bgcolor: theme.palettes.grayscale.lighter,
            },
          }} dense={true}>
            {sortBy(users, ["email"]).map((p) => {
              return (
                <DenseItemAvatar
                  origin="admins"
                  id={
                    p.email !== null
                      ? "admin-li-item-" + p.email
                      : "admin-person-li-item"
                  }
                  key={p.email}
                  primaryInfo={p.name !== null ? p.name : ""}
                  secondaryInfo={p.email !== null ? p.email : ""}
                  type={"person"}
                  isBold={false}
                  canAdministrate={isAdministrator}
                  onDelete={() => removeAdmin(p.email)}
                  onClick={() => props.onPersonClick(p)}
                />
              );
            })}
          </GroupsList>
        </PaperContentDiv>
      );
    }

    return (
      <PaperContentCenterDiv id="admins-no-content-div">
        <NoAdminsFound />
      </PaperContentCenterDiv>
    );
  };

  return (
    <GroupsColumnDiv id="admins-column" width={30} height={100}>
      <PaperControl title={"Admins"} selfHelp={SelfHelp.Admins}>
        <InputDiv>
          <LinearProgressDiv>
            {(isRemovingAdmin || isAddingAdmin) && (
              <StyledLinearProgress />
            )}
          </LinearProgressDiv>
          <AdminsSearchControl
            minSearchLength={3}
            isDisabled={isAdminsFetching}
          />
          <AddAdminControl
            isDisabled={!isAdministrator}
            onAddAdmin={addAdmin}
          />
        </InputDiv>
        {content()}
      </PaperControl>
    </GroupsColumnDiv>
  );
}
