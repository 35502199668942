import { Placement, Step } from "react-joyride";
import { SelfHelp } from "../../../models/selfHelpType";
import { menuBarSteps } from "../steps/menuBar/menuBarSteps";
import { peopleSteps } from "../steps/people/peopleSteps";
import { adminsSteps } from "../steps/admins/adminsSteps";
import { categoriesSteps } from "../steps/categories/categoriesSteps";
import { memberOfSteps } from "../steps/memberOf/memberOfSteps";
import { membersSteps } from "../steps/members/membersSteps";


export const createStep = (
  target: string,
  content: React.ReactNode,
  width: number,
  placement: Placement | "auto" | "center"
): Step => {
  return {
    target: target,
    content: content,
    placement: placement,
    disableOverlayClose: true,
    disableScrolling: true,
    disableBeacon: true,
    styles: {
      tooltip: {
        zIndex: 1400,
        width: width,
      },
      tooltipContent: {
        zIndex: 1400,
        padding: 8,
      },
    },
  };
};

export const getSelfHelpSteps = (selfHelp: SelfHelp, isAdminInCurrentGroup: boolean | undefined, isUserAdminInAnyAccessGroup: boolean, isAnyAdmins: boolean, isAnyMembers: boolean, isAnyCategories: boolean, isAnyMemberOf: boolean, isAnyRemoveButton: boolean, noUsersFound: boolean, noAdminsFound: boolean): Step[] => {
  switch (selfHelp) {
    case SelfHelp.Admins:
      return adminsSteps(isAdminInCurrentGroup, isUserAdminInAnyAccessGroup, isAnyAdmins, noAdminsFound);
    case SelfHelp.Categories:
      return categoriesSteps(isAnyCategories);
    case SelfHelp.MemberOf:
      return memberOfSteps(isAnyMemberOf);
    case SelfHelp.Members:
      return membersSteps(isAdminInCurrentGroup, isUserAdminInAnyAccessGroup, isAnyMembers, noUsersFound);
    case SelfHelp.MenuBar:
      return menuBarSteps();
    case SelfHelp.People:
      return peopleSteps(isAnyRemoveButton);
    default:
      throw new Error("Unsupported CurrentSelfHelp value");
  }
};
