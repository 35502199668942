import { createApi } from "@reduxjs/toolkit/query/react";
import { AccessGroup } from "../../models/accessGroup";
import { ApiRequest } from "../../models/apiRequest";
import { apiBaseQuery } from "./baseQueries";
import { Category } from "../../models/category";

export const accessGroupsApi = createApi({
  reducerPath: "accessGroupsApi",
  baseQuery: apiBaseQuery,
  endpoints: (builder) => ({
    getUserAdminAccessGroups: builder.query<string[], ApiRequest>({
      query: (request) => ({
        url: `api/v${request.version}/Managed/AccessGroups`,
        method: "GET",
      }),
    }),
    getAllAccessGroups: builder.query<AccessGroup[], ApiRequest>({
      query: (request) => ({
        url: `api/v${request.version}/Service/AccessGroups`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
    getAccessGroupChildren: builder.query<
      AccessGroup[],
      ApiRequest & { accessGroupName: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Service/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/ChildAccessGroups`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
    getAccessGroupCategories: builder.query<
      Category[],
      ApiRequest & { accessGroupName: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Service/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/Categories`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useLazyGetUserAdminAccessGroupsQuery,
  useGetAllAccessGroupsQuery,
  useLazyGetAccessGroupChildrenQuery,
  useLazyGetAccessGroupCategoriesQuery,
} = accessGroupsApi;

export default accessGroupsApi.reducer;
