import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/members/members.png";

export function MembersNotFoundStep() {
    const theme = useTheme();
    return (
        <StepContentDiv>
            <Paragraph>
                Once a user is added to the group, they will be listed in this section.
            </Paragraph>
            <Paragraph>
                Groups that are members of this group are listed at the top.
            </Paragraph>
            <Paragraph>
                Groups that current user can manage are in{" "}
                <span style={{ fontWeight: "bold" }}>
                    bold, black font
                </span>. Groups to which current user does not have administrator rights are{" "}
                <span style={{ color: theme.palettes.grayscale.medium }}>
                    greyed-out
                </span>.
            </Paragraph>
            <Paragraph>
                Members are listed below the groups if there are any.
            </Paragraph>
            <Paragraph>
                Clicking on an email address will open the People section with the user membership. Clicking on a group name will open the Groups section for that group.
            </Paragraph>
            <Paragraph>
                <Screenshot src={screenshot} />
            </Paragraph>
        </StepContentDiv>
    );
}
