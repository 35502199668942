import { Step } from "react-joyride";
import { createStep } from "../../helpers/selfHelpBuilder";
import { MemberOfSectionStep } from "./controls/MemberOfSectionStep";
import { MemberOfSectionStep2 } from "./controls/MemberOfSectionStep2";
import { ContentStep } from "./controls/ContentStep";
import { NoContentStep } from "./controls/NoContentStep";

export const memberOfSteps = (isAnyMemberOf: boolean): Step[] => {
  const steps: Step[] = [];

  steps.push(createStep("#member-of-column", <MemberOfSectionStep />, 620, "left"));

  if (isAnyMemberOf) {
    steps.push(createStep("#members-of-content", <ContentStep />, 620, "left"));
  } else {
    steps.push(createStep("#members-of-no-content", <NoContentStep />, 620, "left"));
  }

  steps.push(createStep("#member-of-column", <MemberOfSectionStep2 />, 620, "left"));

  return steps;
};
