import { createApi } from "@reduxjs/toolkit/query/react";
import { apiBaseQuery } from "./baseQueries";
import { ApiRequest } from "../../models/apiRequest";
import { extractFullNameFromEmail } from "../../helpers/emailHelper";
import { Person } from "../../models/person";
import { RemoveFromGroupResponse } from "../../models/removeFromGroupResponse";

export const accessGroupsAdminsApi = createApi({
  reducerPath: "accessGroupsAdminsApi",
  baseQuery: apiBaseQuery,
  endpoints: (builder) => ({
    getAccessGroupAdmins: builder.query<
      Person[],
      ApiRequest & { accessGroupName: string; searchQuery: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Service/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/Admins?searchQuery=${request.searchQuery}`,
        method: "GET",
      }),
      transformResponse: (response: string[]): Person[] =>
        response.map((email) => ({
          name: extractFullNameFromEmail(email),
          email,
        })),
      keepUnusedDataFor: 3600,
    }),
    removeAdminFromAccessGroup: builder.mutation<
      RemoveFromGroupResponse,
      ApiRequest & { accessGroupName: string; userEmail: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Managed/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/Admins/${request.userEmail}`,
        method: "DELETE",
      }),
    }),
    addAdminFromAccessGroup: builder.mutation<
      RemoveFromGroupResponse,
      ApiRequest & { accessGroupName: string; userEmail: string }
    >({
      query: (request) => ({
        url: `api/v${request.version}/Managed/AccessGroups/${encodeURIComponent(
          request.accessGroupName
        )}/Admins/${request.userEmail}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useLazyGetAccessGroupAdminsQuery,
  useAddAdminFromAccessGroupMutation,
  useRemoveAdminFromAccessGroupMutation,
} = accessGroupsAdminsApi;

export default accessGroupsAdminsApi.reducer;
