import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AddUserStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                In this section a group administrator can add a new member to the group using their full email address.
                There is no need to add internal ABB users because they have access to all documents.
            </Paragraph>
            <Paragraph>
                When current user is not the administrator of the selected group, they will not see this field.
            </Paragraph>
        </StepContentDiv>
    );
}
