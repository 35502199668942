import styled from "styled-components";

interface SearchInputDiv {
  $width: number;
}

export const SearchInputDiv = styled.div<SearchInputDiv>`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  width: ${(props) => props.$width + "px"};
`;
