import { Tab } from "@mui/material";
import styled from "styled-components";

export const StyledTab = styled(Tab)`
  color: ${(props) => props.theme.palettes.grayscale.medium} !important;

  &.Mui-selected {
    color: ${(props) => props.theme.palettes.primary.dark} !important;
  }
`;
