import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  Skeleton,
} from "@mui/material";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { selectIsSuperAdmin } from "../../app/slices/authenticationSlice";
import {
  setCurrentMembersSearch,
  setSearchedMemberEmail,
} from "../../app/slices/groupsSlice";
import { setTabType } from "../../app/slices/mainViewSlice";
import {
  PersonAccessGroup,
  setSelectedPersonAccessGroup,
} from "../../app/slices/peopleSlice";
import { useRemoveUser } from "../../components/Groups/GroupColumn/hooks/Users/useRemoveUser";
import { MainViewTabType } from "../../models/MainViewTabType";
import { Person } from "../../models/person";
import { DialogControl } from "../Dialogs/DialogControl";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { StyledAccessGroupName } from "./SC/StyledAccessGroupName";

interface AccessGroupInfoProps {
  accessGroup: PersonAccessGroup;
  icon: ReactNode;
  isLoading: boolean;
  person: Person;
  onAccessGroupRemoval: (accessGroup: PersonAccessGroup) => void;
}

export function UserAccessGroupItemAvatar(props: AccessGroupInfoProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);
  const { removeUser, isRemovingUser, isSuccess } = useRemoveUser();
  const isSuperAdmin = useAppSelector(selectIsSuperAdmin);
  const onAccessGroupRemoval = props.onAccessGroupRemoval;

  const removeUserFromGroup = useCallback(() => {
    if (props.person.email) {
      removeUser(props.person.email, props.accessGroup.name);
      setIsRemovePopupOpen(false);
    }
  }, [removeUser, props.person.email, props.accessGroup.name]);

  const getSecondaryAction = () => {
    if (isRemovingUser) {
      return (
        <CircularProgress
          sx={{ color: theme.palettes.primary.main }}
          size="20px"
        />
      );
    }

    if (isSuperAdmin || props.accessGroup.currentUserHasAdminAccess) {
      return (
        <TooltipTop title="Click to remove user from access group">
          <IconButton
            className="delete-button"
            edge="end"
            aria-label="delete"
            onClick={() => {
              setIsRemovePopupOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TooltipTop>
      );
    }

    return <></>;
  };

  useEffect(() => {
    if (isSuccess) {
      onAccessGroupRemoval(props.accessGroup);
    }
  }, [isSuccess, props.accessGroup, onAccessGroupRemoval]);

  return (
    <>
      <ListItem
        secondaryAction={getSecondaryAction()}
        id={`user-access-group-${props.accessGroup.name}`}
      >
        <ListItemAvatar>
          <Avatar>{props.icon}</Avatar>
        </ListItemAvatar>
        {props.isLoading ? (
          <Skeleton height={theme.skeletons.small} width={"100%"} />
        ) : (
          <StyledAccessGroupName
            $highlightBlack={!!props.accessGroup.currentUserHasAdminAccess}
            onClick={() => {
              dispatch(setTabType(MainViewTabType.Groups));
              dispatch(setSelectedPersonAccessGroup(props.accessGroup));
              dispatch(setCurrentMembersSearch(""));
              dispatch(setSearchedMemberEmail(""));
            }}
          >
            {props.accessGroup.name}
          </StyledAccessGroupName>
        )}
      </ListItem>
      <DialogControl
        id={"remove-user"}
        isOpen={isRemovePopupOpen}
        useActions={true}
        title={"Remove user from the access group"}
        content={"Do you confirm user removal from the access group?"}
        fullWidth={true}
        maxWidth={"sm"}
        onCloseClick={() => setIsRemovePopupOpen(false)}
        onConfirmClick={removeUserFromGroup}
      />
    </>
  );
}
