import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function AdminRowStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                Clicking on an email address will open the People section with the user membership.
            </Paragraph>
        </StepContentDiv>
    );
}
