import { TextField } from "@mui/material";
import styled from "styled-components";

interface StyledTextFieldProps {
  padding?: number;
  $wrapText?: boolean;
  $isEditable: boolean;
  $isEdited?: boolean;
}

function setPadding(padding?: number): number {
  if (padding) {
    return padding;
  }

  return 0;
}

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  &.MuiFormControl-root {
    flex-grow: 1;
  }

  &.MuiFormControl-root .MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.palettes.grayscale.darkest};
  }

  & .MuiOutlinedInput-root {
    pointer-events: ${(props) => (props.$isEditable ? "auto" : "none")};
    white-space: ${(props) => (props.$wrapText ? "pre-line" : "normal")};
    fieldset {
      border-color: ${(props) =>
        props.$isEditable
          ? props.theme.palettes.grayscale.medium
          : props.theme.palettes.grayscale.white};
    }

    padding-right: ${(props) => setPadding(props.padding)}px;

    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.palettes.grayscale.darkest};
    }

    & :hover {
      fieldset {
        border-color: ${(props) =>
          props.$isEditable
            ? props.theme.palettes.grayscale.medium
            : props.theme.palettes.grayscale.white};
      }
    }
  }
`;
