import { DefaultTheme } from "styled-components";
import { createTheme } from "@mui/material/styles";

export const defaultTheme: DefaultTheme = {
  palettes: {
    primary: {
      light: "#ffffff",
      main: "#ff000f",
      green: "#007a33",
      yellow: "#ffd100",
      dark: "#000000",
    },
    secondary: {
      approval: "#004c97",
      draft: "#ffd100",
      published: "#02a102",
      deleted: "#CC293D",
      archived: "#6e6e6e",
      error: "#f03040",
      warning: "#ff7300",
      info: "#3366ff",
      success: "#0ca919",
      premium: "#267bbb",
      internal: "#ada333",
      public: "#007a33",
      highlight: "#ffff00",
    },
    grayscale: {
      white: "#ffffff",
      lightest: "#fafafa",
      lightest2: "#f3f3f3",
      lighter: "#f0f0f0",
      light: "#d2d2d2",
      medium: "#a9a9a9",
      dark: "#6e6e6e",
      darkest: "#262626",
      boxShadow: "#0000001a",
      boxShadowDarker: "#00000033",
      disabledBackground: "#0000001E",
      disabledColor: "#00000042",
      darkerHighlight: "#00000026",
      darkHighlight: "#00000059",
      disabledHighlight: "#00000014",
    },
  },
  sectionHeader: {
    minWidth: 500,
  },
  typography: {
    fontFamily: "ABBLibrary - ABBvoice",
    fontSizes: {
      small: 10,
      medium: 12,
      large: 13,
      extraSmallIcon: 11,
      smallIcon: 16,
      headerT1: 18,
      headerT2: 16,
      headerT3: 14,
      headerT4: 12,
    },
  },
  density: {
    compactRowHeight: 24,
    standardRowHeight: 42,
    comfortRowHeight: 50,
    compactRowSpacing: 4,
    standardRowSpacing: 6,
    comfortRowSpacing: 8,
  },
  shapes: {
    toggleButtonsHeight: 34,
    primaryButtonHeight: 32,
    secondaryButtonHeight: 24,
    verticalButtonHeight: 48,
    smallChipHeight: 16,
    mediumChipHeight: 20,
    defaultChipHeight: 24,
    defaultCategoryTreePadding: 16,
  },
  skeletons: {
    small: 30,
    medium: 60,
    large: 90,
  },
  circularProgressWrapper: {
    defaultHeight: 160,
  },
  circularProgress: {
    small: 20,
    medium: 42,
    large: 80,
  },
};
export const muiTheme = createTheme({
  typography: {
    fontFamily: ["'ABBLibrary - ABBvoice'"].join(","),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: defaultTheme.palettes.grayscale.white,
          border: `1px solid ${defaultTheme.palettes.grayscale.medium}`,
          borderRadius: "4px",
          fontFamily: ["'ABBLibrary - ABBvoice'"].join(","),
          boxShadow: "none",
          padding: "4px 0",
        },
        paper: {
          "&.MuiPaper-root": {
            maxHeight: "220px",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.typography.fontSizes.medium,
          ":hover": {
            backgroundColor: defaultTheme.palettes.grayscale.lightest2,
          },
          "&.Mui-selected": {
            backgroundColor: defaultTheme.palettes.grayscale.dark,
            ":hover": {
              backgroundColor: defaultTheme.palettes.grayscale.light,
            },
            "&.Mui-focusVisible": {
              backgroundColor: defaultTheme.palettes.grayscale.light,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":disabled": {
            cursor: "not-allowed",
            pointerEvents: "all",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ":disabled": {
            cursor: "not-allowed",
            pointerEvents: "all",
            color: defaultTheme.palettes.grayscale.disabledColor,
            backgroundColor: defaultTheme.palettes.grayscale.disabledBackground,
            ":hover": {
              color: defaultTheme.palettes.grayscale.disabledColor,
              backgroundColor:
                defaultTheme.palettes.grayscale.disabledBackground,
            },
          },
        },
        sizeSmall: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
        sizeMedium: {
          fontSize: defaultTheme.typography.fontSizes.large,
        },
        sizeLarge: {
          fontSize: defaultTheme.typography.fontSizes.headerT2,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: defaultTheme.typography.fontSizes.small,
        },
        sizeMedium: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
        sizeLarge: {
          fontSize: defaultTheme.typography.fontSizes.large,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        sizeSmall: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        sizeSmall: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: defaultTheme.typography.fontSizes.medium,
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: defaultTheme.palettes.grayscale.lighter,
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: defaultTheme.palettes.grayscale.light,
          },
        },
        option: {
          "&.Mui-focused": {
            backgroundColor: defaultTheme.palettes.grayscale.light,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
        fullWidth: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
        sizeMedium: {
          fontSize: defaultTheme.typography.fontSizes.large,
        },
        root: {
          minHeight: defaultTheme.shapes.defaultChipHeight,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.typography.fontSizes.medium,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: defaultTheme.palettes.grayscale.white,
          "&.Mui-focused": {
            borderColor: defaultTheme.palettes.primary.main,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          display: "flex",
          marginLeft: "-10px",
          "&.Mui-checked": {
            color: defaultTheme.palettes.primary.main,
          },
        },
        track: {
          backgroundColor: defaultTheme.palettes.grayscale.medium,
          "&.Mui-checked": {
            backgroundColor: defaultTheme.palettes.grayscale.medium,
          },
        },
        switchBase: {
          color: defaultTheme.palettes.grayscale.lighter,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          wordBreak: "break-word",
          display: "flex",
          fontSize: defaultTheme.typography.fontSizes.small,
          color: defaultTheme.palettes.grayscale.dark,
          backgroundColor: defaultTheme.palettes.grayscale.lightest,
          border: `1px solid ${defaultTheme.palettes.grayscale.dark}`,
          borderRadius: "0px",
          boxShadow: "0px 0px 24px #00000034",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderSpacing: "0px",
          borderLeft: "1px solid " + defaultTheme.palettes.grayscale.light,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: "pre-line",
          borderBottom: "1px solid " + defaultTheme.palettes.grayscale.light,
          borderRight: "1px solid " + defaultTheme.palettes.grayscale.light,
          padding: "8px",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palettes.grayscale.light,
        },
        bar: {
          backgroundColor: defaultTheme.palettes.primary.main,
        },
      },
    },
  },
  palette: {
    primary: {
      main: defaultTheme.palettes.primary.main,
    },
    secondary: {
      main: defaultTheme.palettes.grayscale.medium,
    },
  },
});
