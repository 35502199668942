import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                Admins section is used to display administrators of the selected group.
            </Paragraph>
            <Paragraph>
                Administrator is a person authorized to manage user rights within their assigned access groups.
            </Paragraph>
        </StepContentDiv>
    );
}
