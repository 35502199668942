import styled from "styled-components";

interface ErrorHeaderDivProps {
  $centerText: boolean;
}

export const ErrorHeaderDiv = styled.div<ErrorHeaderDivProps>`
  font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
  font-weight: bold;
  text-align: ${(props) => (props.$centerText ? "center" : "start")};
`;
