import { StyledWarningIcon } from "../StyledComponents/StyledWarningIcon";
import { StyledErrorIcon } from "./SC/StyledErrorIcon";
import { StyledFormHelperSpan } from "./SC/StyledFormHelperSpan";
import { StyledFormHelperText } from "./SC/StyledFormHelperText";
import { StyledFormInfoIcon } from "./SC/StyledFormInfoIcon";

interface FormHelperControlProps {
  isWarning: boolean;
  isError: boolean;
  isInfo?: boolean;
  isVisible?: boolean;
  texts: string[];
  marginTop?: number;
  marginBottom?: number;
}

export function FormHelperControl(props: FormHelperControlProps) {
  return (
    <StyledFormHelperText
      $isWarning={props.isWarning}
      $isError={props.isError}
      $isInfo={props.isInfo}
      $marginTop={props.marginTop}
      $marginBottom={props.marginBottom}
      $isVisible={props.isVisible ?? true}
    >
      <StyledFormHelperSpan>
        {props.texts.map((text, index) => {
          const icon = props.isError ? (
            <StyledErrorIcon fontSize="inherit" />
          ) : props.isWarning ? (
            <StyledWarningIcon margin={0} fontSize="inherit" />
          ) : props.isInfo ? (
            <StyledFormInfoIcon fontSize="inherit" />
          ) : null;

          return (
            <span key={index}>
              {icon} <span>{text}</span>
            </span>
          );
        })}
      </StyledFormHelperSpan>
    </StyledFormHelperText>
  );
}
