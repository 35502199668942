import { Paper } from "@mui/material";
import styled from "styled-components";

export const StyledPaper = styled(Paper)`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 8px;
  margin-left: 8px;
  max-width: calc(100% - 16px);
`;
