import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { SnackbarType } from "../../models/snackbar";
import { openSnackbar } from "../helpers/snackbarHelpers";
import { useAppDispatch } from "./hooks";

interface ApiErrorHandlingProps {
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  customErrorMessage: string;
}

function useApiErrorHandling(props: ApiErrorHandlingProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.isError) {
      let message = props.customErrorMessage;
      if (
        props.error &&
        "data" in props.error &&
        typeof props.error.data === "string" &&
        "originalStatus" in props.error &&
        typeof props.error.originalStatus === "number" &&
        props.error.originalStatus !== 500
      ) {
        message = props.error.data;
      }

      openSnackbar(dispatch, message, SnackbarType.error);
    }
  }, [dispatch, props.isError, props.customErrorMessage, props.error]);
}

export default useApiErrorHandling;
