import styled from "styled-components";

interface StyledAutocompleteErrorDivProps {
  $width: number;
  $topMargin: number;
}

const StyledAutocompleteErrorDiv = styled.div<StyledAutocompleteErrorDivProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border-color: ${(props) => props.theme.palettes.primary.dark};
  padding: 6px;
  border: ${(props) =>
    `1px solid ${props.theme.palettes.grayscale.disabledColor}`};
  gap: 5px;
  position: absolute;
  margin-top: ${(props) => props.$topMargin + "px"};
  width: ${(props) => props.$width + "px"};
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palettes.primary.light};
`;

export default StyledAutocompleteErrorDiv;
