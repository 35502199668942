import styled from "styled-components";

interface InfoHeaderDivProps {
  doCenterHeader?: boolean;
}

export const InfoHeaderDiv = styled.div<InfoHeaderDivProps>`
  font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
  font-weight: bold;
  text-align: ${(props: InfoHeaderDivProps) => (props.doCenterHeader ? "center" : "unset")};
`;
