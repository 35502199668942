import { DashDiv } from "./SC/DashDiv";
import { InfoBoxDiv } from "./SC/InfoBoxDiv";
import { InfoContainerDiv } from "./SC/InfoContainerDiv";
import { InfoHeaderDiv } from "./SC/InfoHeaderDiv";
import InfoMessage from "./SC/InfoMessage";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ReloadButtonDiv } from "./SC/ReloadButtonDiv";
import { StyledReloadButton } from "./SC/StyledReloadButton";

export interface ErrorDisplayProps {
  id: string;
  title?: string;
  description?: string;
  $showDash?: boolean;
  showReloadButton?: boolean;
  doCenterHeader?: boolean;
  refreshFunction?: () => void;
  padding?: string;
}

export function InformationControl(props: ErrorDisplayProps) {
  return (
    <InfoContainerDiv
      id={props.id + "-error-container"}
      padding={props.padding}
    >
      <InfoBoxDiv id={props.id + "-error-box"}>
        <InfoHeaderDiv doCenterHeader={props.doCenterHeader}>
          {props.title}
        </InfoHeaderDiv>
        {<DashDiv $showDash={props.$showDash} />}
        <InfoMessage id={props.id + "-error-message"}>
          {props.description}
        </InfoMessage>

        {props.showReloadButton && (
          <ReloadButtonDiv>
            <StyledReloadButton
              id={"refresh-button-id"}
              variant="contained"
              startIcon={<RefreshIcon fontSize="small" />}
              color="error"
              onClick={props.refreshFunction}
            >
              Reload
            </StyledReloadButton>
          </ReloadButtonDiv>
        )}
      </InfoBoxDiv>
    </InfoContainerDiv>
  );
}
