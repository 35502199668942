import { useCallback } from "react";
import { useLazyGetAccessGroupChildrenQuery } from "../../../../../app/apis/accessGroupsApi";
import { useAppSelector } from "../../../../../app/hooks/hooks";
import useApiErrorHandling from "../../../../../app/hooks/useApiErrorHandling";
import { selectCurrentSearchAccessGroup } from "../../../../../app/slices/groupsSlice";

export function useGetGroups() {
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);

  const [
    getAccessGroupChildren,
    {
      data: children,
      error,
      isError: isChildrenError,
      isFetching: isChildrenFetching,
    },
  ] = useLazyGetAccessGroupChildrenQuery();
  useApiErrorHandling({
    isError: isChildrenError,
    error,
    customErrorMessage: "Error while fetching access group children",
  });

  const fetchChildren = useCallback(() => {
    if (currentGroup?.name) {
      void getAccessGroupChildren(
        {
          version: 1,
          accessGroupName: currentGroup.name,
        },
        true
      );
    }
  }, [currentGroup?.name, getAccessGroupChildren]);

  return { fetchChildren, children, isChildrenError, isChildrenFetching };
}
