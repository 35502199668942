import { CircularProgress } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useTheme } from "styled-components";
import { useAppSelector } from "../../../app/hooks/hooks";
import {
  selectAdminAccessGroups,
} from "../../../app/slices/groupsSlice";
import { ErrorDisplay } from "../../../controls/ErrorHandling/ErrorDisplay";
import { DenseItemAvatar } from "../../../controls/ItemAvatar/DenseItemAvatar";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { PaperContentCenterDiv } from "../../../controls/Paper/SC/PaperContentCenterDiv";
import { PaperContentDiv } from "../../../controls/Paper/SC/PaperContentDiv";
import { orderGroupsByNameAndAccess } from "../../../helpers/accessGroupsHelper";
import { AccessGroupWithPermissions } from "../../../models/accessGroupWithPermissions";
import { NoParentsFound } from "./NotFound/NoParentsFound";
import { GroupsColumnDiv } from "./SC/GroupsColumnDiv";
import { GroupsList } from "./Search/SC/GroupsList";
import { SelfHelp } from "../../../models/selfHelpType";
import { useGetParents } from "./hooks/MemberOf/useGetParents";

interface MemberOfGroupColumnProps {
  onAccessGroupClick: (group: AccessGroupWithPermissions) => void;
}

function MemberOfGroupColumn(props: MemberOfGroupColumnProps) {
  const theme = useTheme();
  const adminGroups = useAppSelector(selectAdminAccessGroups);

  const { fetchParents, parents, isParentsFetching, isParentsError } = useGetParents();


  const parentsWithAccess = useMemo(() => {
    const parentsWithPermissions: AccessGroupWithPermissions[] | undefined =
      parents?.map((x) => ({
        id: x.id,
        name: x.name,
        hasAdminAccess: !!adminGroups.find((y) => y.name === x.name),
      }));

    return parentsWithPermissions?.sort(orderGroupsByNameAndAccess);
  }, [parents, adminGroups]);

  const getContent = () => {
    if (isParentsFetching) {
      return (
        <PaperContentCenterDiv>
          <CircularProgress
            sx={{ color: theme.palettes.primary.main }}
            size="30px"
          />
        </PaperContentCenterDiv>
      );
    }

    if (isParentsError) {
      return (
        <PaperContentCenterDiv>
          <ErrorDisplay
            errorId={"member-of"}
            errorMessageTitle={"Something went wrong"}
            errorDescription={"Error while loading access group parents."}
            refreshFunction={fetchParents}
            $showDash={false}
            showReloadButton={true}
            centerHeader={true}
          />
        </PaperContentCenterDiv>
      );
    }

    if (parentsWithAccess?.length === 0) {
      return (
        <PaperContentCenterDiv id="members-of-no-content">
          <NoParentsFound />
        </PaperContentCenterDiv>
      );
    }

    return (
      <PaperContentDiv id="members-of-content">
        <GroupsList id={"member-of-list"} dense={true}>
          {parentsWithAccess?.map((ag) => (
            <DenseItemAvatar
              origin="member-of"
              id={"group-li-item-" + ag.name}
              key={ag.id}
              primaryInfo={ag.name}
              secondaryInfo={""}
              type={"group"}
              isBold={ag.hasAdminAccess}
              canAdministrate={ag.hasAdminAccess}
              onClick={() => props.onAccessGroupClick(ag)}
            />
          ))}
        </GroupsList>
      </PaperContentDiv>
    );
  };

  useEffect(() => {
    fetchParents();
  }, [fetchParents]);

  return (
    <GroupsColumnDiv width={100} height={50} id={"member-of-column"}>
      <PaperControl title={"Member of"} selfHelp={SelfHelp.MemberOf}>{getContent()}</PaperControl>
    </GroupsColumnDiv>
  );
}

export default MemberOfGroupColumn;
