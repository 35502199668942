import { FilterOptionsState } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useGetAllAccessGroupsQuery } from "../../app/apis/accessGroupsApi";
import { openSnackbar } from "../../app/helpers/snackbarHelpers";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  selectAdminAccessGroups,
  selectAllAccessGroups,
  selectCurrentGroupsSearch,
  selectCurrentSearchAccessGroup,
  setAllAccessGroups,
  setCurrentSearchAccessGroup,
  setCurrentGroupsSearch,
  clearInputs,
} from "../../app/slices/groupsSlice";
import {
  selectSelectedPersonAccessGroup,
  setSelectedPersonAccessGroup,
} from "../../app/slices/peopleSlice";
import { SnackbarType } from "../../models/snackbar";
import { AutocompleteControl } from "../AutocompleteControl/AutocompleteControl";
import { AccessGroupWithPermissions } from "../../models/accessGroupWithPermissions";

const sortSearchAccessGroups = (
  a: AccessGroupWithPermissions,
  b: AccessGroupWithPermissions
) => {
  const aLower = a.name.toLocaleLowerCase();
  const bLower = b.name.toLocaleLowerCase();

  if (a.hasAdminAccess === b.hasAdminAccess) {
    return aLower.localeCompare(bLower);
  }

  return a.hasAdminAccess ? -1 : 1;
};

const onOptionsFilter = (
  options: AccessGroupWithPermissions[],
  state: FilterOptionsState<AccessGroupWithPermissions>,
  inputValue: string
) => {
  const input =
    state.inputValue === "" && inputValue !== ""
      ? inputValue
      : state.inputValue;
  const trimmed = input.trim().toLocaleLowerCase();
  const groupId = !isNaN(Number(trimmed)) ? parseInt(trimmed) : undefined;
  const filteredById = groupId ? options.filter((x) => x.id === groupId) : [];

  return trimmed.length >= 3
    ? options
      .filter(
        (x) =>
          x.name.toLocaleLowerCase().includes(trimmed) && x.id !== groupId
      )
      .concat(filteredById)
    : groupId
      ? filteredById
      : options;
};

export function GroupSearchInput() {
  const dispatch = useAppDispatch();
  const allAccessGroups = useAppSelector(selectAllAccessGroups);
  const adminAccessGroups = useAppSelector(selectAdminAccessGroups);
  const selectedAccessGroup = useAppSelector(selectSelectedPersonAccessGroup);
  const currentAccessGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const currentSearchValue = useAppSelector(selectCurrentGroupsSearch);
  const {
    data: allGroups,
    isError,
    isFetching,
    refetch,
  } = useGetAllAccessGroupsQuery({
    version: 1,
  });

  const allSearchGroups: AccessGroupWithPermissions[] = useMemo(() => {
    return allAccessGroups
      .map((x) => ({
        id: x.id,
        name: x.name,
        hasAdminAccess: !!adminAccessGroups.find((y) => y.name == x.name),
      }))
      .sort(sortSearchAccessGroups);
  }, [allAccessGroups, adminAccessGroups]);

  useEffect(() => {
    if (isError) {
      openSnackbar(
        dispatch,
        "Access groups loading failed",
        SnackbarType.error
      );
    }
  }, [isError, dispatch]);

  useEffect(() => {
    if (allGroups) {
      dispatch(setAllAccessGroups(allGroups));
    }
  }, [dispatch, allGroups]);

  useEffect(() => {
    if (!selectedAccessGroup) {
      return;
    }

    const group = allSearchGroups.find(
      (x) => x.name === selectedAccessGroup.name
    );

    if (group) {
      dispatch(setCurrentSearchAccessGroup(group));
      dispatch(setCurrentGroupsSearch(group.name));
    }
  }, [dispatch, selectedAccessGroup, allSearchGroups]);

  const onSearchChange = (value: AccessGroupWithPermissions | null) => {
    dispatch(setCurrentSearchAccessGroup(value));
    dispatch(setSelectedPersonAccessGroup(undefined));
    dispatch(clearInputs())
  };

  const onInputChange = (value: string) => {
    dispatch(setCurrentGroupsSearch(value));
  };

  const onRefreshHandler = useCallback(() => {
    void refetch();
  }, [refetch]);

  return (
    <AutocompleteControl
      id="groups"
      options={allSearchGroups}
      onChangeHandler={onSearchChange}
      initialValue={currentAccessGroup}
      selectedGroupName={selectedAccessGroup?.name}
      initialInputValue={currentSearchValue}
      onInputChange={onInputChange}
      isFetchingError={isError && !isFetching}
      isLoading={isFetching}
      onRefreshHandler={onRefreshHandler}
      onOptionsFilter={onOptionsFilter}
      width={598}
    />
  );
}
