import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function ContentHasAccessRemoveStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                Administrator can remove an administrator from the group by clicking on the bin.
            </Paragraph>
        </StepContentDiv>
    );
}
