import { useCallback, useEffect } from "react";
import { useRemoveUserFromAccessGroupMutation } from "../../../../../app/apis/accessGroupsUsersApi";
import { openSnackbar } from "../../../../../app/helpers/snackbarHelpers";
import { useAppDispatch } from "../../../../../app/hooks/hooks";
import useApiErrorHandling from "../../../../../app/hooks/useApiErrorHandling";
import { setRefreshAccessGroupsUsers } from "../../../../../app/slices/groupsSlice";
import { SnackbarType } from "../../../../../models/snackbar";

export function useRemoveUser() {
  const dispatch = useAppDispatch();
  const [
    removeUserQuery,
    { isError, error, isLoading: isRemovingUser, isSuccess },
  ] = useRemoveUserFromAccessGroupMutation();
  useApiErrorHandling({
    isError,
    error,
    customErrorMessage: "Error while removing user from an access group",
  });

  const removeUser = useCallback(
    (userEmail: string | null, currentGroupName?: string) => {
      if (userEmail && currentGroupName) {
        void removeUserQuery({
          version: 1,
          accessGroupName: currentGroupName,
          userEmail,
        });
      }
    },
    [removeUserQuery]
  );

  useEffect(() => {
    if (isSuccess) {
      openSnackbar(
        dispatch,
        "User removed from the access group successfully",
        SnackbarType.success
      );

      dispatch(setRefreshAccessGroupsUsers(true));
    }
  }, [dispatch, isSuccess]);

  return { removeUser, isRemovingUser, isSuccess };
}
