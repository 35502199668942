import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { ListItemIcon, ListItemText, Menu } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "../../../app/hooks/hooks";
import {
  selectUserEmail,
  selectUserName,
  selectUserPhoto,
} from "../../../app/slices/authenticationSlice";
import { TooltipPartDiv } from "../../../controls/Tooltips/SC/TooltipPartDiv";
import { TooltipPartSpan } from "../../../controls/Tooltips/SC/TooltipPartSpan";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import { StyledAvatar } from "./SC/StyledAvatar";
import { StyledMenuItem } from "./SC/StyledMenuItem";
import { StyledSkeleton } from "./SC/StyledSkeleton";
import { UserDataDiv } from "./SC/UserDataDiv";
import { UserInfoDiv } from "./SC/UserInfoDiv";
import { UserMenuDiv } from "./SC/UserMenuDiv";
import { UserDataRow } from "./UserDataRow";

function getInitials(fullName: string | undefined) {
  if (fullName) {
    const names = fullName.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  return "XY";
}

export function UserInfo() {
  const userEmail = useAppSelector(selectUserEmail);
  const userName = useAppSelector(selectUserName);
  const imageUrl = useAppSelector(selectUserPhoto);
  const { instance: msalInstance } = useMsal();
  const account = msalInstance.getActiveAccount();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (account?.idTokenClaims !== undefined) {
    const handleLogout = () => {
      void msalInstance.logoutRedirect({
        account: account,
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      });
    };

    return (
      <UserInfoDiv>
        <UserMenuDiv onClick={handleClick}>
          <UserDataDiv>
            {userName === "" ? (
              <StyledSkeleton variant="text" width={100} />
            ) : (
              <UserDataRow data={userName} $isBolded={true} />
            )}
            {userEmail === "" ? (
              <StyledSkeleton variant="text" width={180} />
            ) : (
              <UserDataRow data={userEmail} $isBolded={false} />
            )}
          </UserDataDiv>
          <TooltipTop
            title={
              <>
                <TooltipPartDiv>
                  <TooltipPartSpan>Name: {userName}</TooltipPartSpan>
                  <TooltipPartSpan>Email: {userEmail}</TooltipPartSpan>
                </TooltipPartDiv>
              </>
            }
          >
            <StyledAvatar src={imageUrl}>{getInitials(userName)}</StyledAvatar>
          </TooltipTop>
        </UserMenuDiv>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <StyledMenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </StyledMenuItem>
        </Menu>
      </UserInfoDiv>
    );
  } else {
    return <></>;
  }
}
