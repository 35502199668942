import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import useApiErrorHandling from "../../../../../app/hooks/useApiErrorHandling";
import { selectCurrentSearchAccessGroup } from "../../../../../app/slices/groupsSlice";
import { setIsAnyMemberOf } from "../../../../../app/slices/selfHelpSlice";
import { useLazyGetAccessGroupParentsQuery } from "../../../../../app/apis/accessGroupsUsersApi";

export function useGetParents() {
  const currentGroup = useAppSelector(selectCurrentSearchAccessGroup);
  const dispatch = useAppDispatch();

  const [getAccessGroupParents, { data: parents, isError: isParentsError, error, isFetching: isParentsFetching }] =
    useLazyGetAccessGroupParentsQuery();
  useApiErrorHandling({
    isError: isParentsError,
    error,
    customErrorMessage: "Error while fetching access group parents",
  });

  const fetchParents = useCallback(() => {
    if (currentGroup?.name) {
      void getAccessGroupParents(
        { version: 1, accessGroupName: currentGroup.name },
        false
      );
    }
  }, [currentGroup?.name, getAccessGroupParents]);

  useEffect(() => {
    if (parents && parents.length > 0) {
      dispatch(setIsAnyMemberOf(true));
    } else {
      dispatch(setIsAnyMemberOf(false));
    }

  }, [parents, dispatch])

  return { fetchParents, parents, isParentsError, isParentsFetching };
}
