import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function PeopleTabStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The People section is used to display selected user access group memberships.
      </Paragraph>
    </StepContentDiv>
  );
}
