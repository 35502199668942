import styled from "styled-components";

export const PaperHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.palettes.grayscale.medium};
  justify-content: space-between;
  height: 34px;
  min-height: 34px;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
`;
