import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import { StyledErrorIcon } from "../FormHelper/SC/StyledErrorIcon";
import StyledAutocompleteErrorDiv from "./SC/StyledAutocompleteErrorDiv";
import StyledSpan from "./SC/StyledSpan";

interface AutocompleteErrorMessageProps {
  errorMessage?: string;
  tooltipTitle?: string;
  refreshHandler?: () => void;
  width: number;
  topMargin: number;
}

const AutocompleteErrorMessage = (props: AutocompleteErrorMessageProps) => {
  return (
    <StyledAutocompleteErrorDiv
      $width={props.width}
      $topMargin={props.topMargin}
    >
      <StyledErrorIcon
        $paddingRight
        color="error"
        fontSize="inherit"
      />
      <StyledSpan>{"No options available"}</StyledSpan>
      <IconButton onMouseDown={props.refreshHandler} size="small">
        <RefreshIcon color="error" fontSize="inherit" />
      </IconButton>
    </StyledAutocompleteErrorDiv>
  );
};

export default AutocompleteErrorMessage;
