import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function MemberOfSectionStep() {
    return (
        <StepContentDiv>
            <Paragraph>
                This section is used to display group membership in other groups.
            </Paragraph>
            <Paragraph>
                A user who is a member of a selected group can access documents assigned to the group they are member of, and additionally the documents available for the access groups listed as ‘Member of’.
            </Paragraph>
            <Paragraph>
                A user who is a member only of a parent group (listed as ‘Member of’) can access only the documents available for that group.
            </Paragraph>
        </StepContentDiv>
    );
}
