import { Avatar, IconButton } from "@mui/material";
import { StyledListItem } from "./SC/StyledListItem";
import { StyledListItemAvatar } from "./SC/StyledListItemAvatar";
import { StyledListItemText } from "./SC/StyledListItemText";
import { noAccessMessage } from "../../components/Groups/GroupColumn/AdminsGroupColumn";
import { TooltipTop } from "../Tooltips/ToolipTop";
import { DialogControl } from "../Dialogs/DialogControl";
import { useState } from "react";
import { StyledFolderIcon } from "./SC/StyledFolderIcon";
import { StyledPersonIcon } from "./SC/StyledPersonIcon";
import { StyledDeleteIcon } from "./SC/StyledDeleteIcon";

interface ItemAvatarProps {
  origin: string;
  id: string;
  primaryInfo: string;
  type: "person" | "group";
  isBold: boolean;
  isBlackColor: boolean;
  canAdministrate?: boolean;
  onClick: () => void;
  onDelete?: () => void;
  style: React.CSSProperties;
}

export function SuperDenseItemAvatar(props: ItemAvatarProps) {
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);

  const removeUserFromGroup = () => {
    if (props.onDelete) {
      props.onDelete();
    }
    setIsRemovePopupOpen(false);
  };

  return (
    <StyledListItem
      style={props.style}
      id={props.id}
      secondaryAction={
        props.type === "person" && (
          <TooltipTop title={props.canAdministrate ? "Click to remove user from access group" : noAccessMessage}>
            <IconButton className={props.origin + "-delete-button"} edge="end" aria-label="delete" onClick={() => setIsRemovePopupOpen(true)} disabled={!props.canAdministrate}>
              <StyledDeleteIcon fontSize="small" />
            </IconButton>
          </TooltipTop>
        )
      }
    >
      <StyledListItemAvatar>
        <Avatar sx={{ width: 20, height: 20 }}>
          {props.type === "group" && (
            <StyledFolderIcon fontSize="small" />
          )}
          {props.type === "person" && (
            <StyledPersonIcon fontSize="small" />
          )}
        </Avatar>
      </StyledListItemAvatar>
      <StyledListItemText
        onClick={() => props.onClick()}
        primary={props.primaryInfo}
        $isBold={props.isBold}
        $isBlackColor={props.isBlackColor}
        $hasPointer={true}
      />
      <DialogControl
        id={"remove-user"}
        isOpen={isRemovePopupOpen}
        useActions={true}
        title={"Remove user from the access group"}
        content={`Do you confirm removing ${props.primaryInfo} from this access group?`}
        fullWidth={true}
        maxWidth={"sm"}
        onCloseClick={() => setIsRemovePopupOpen(false)}
        onConfirmClick={removeUserFromGroup}
      />
    </StyledListItem>
  );
}
